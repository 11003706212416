import React, { useEffect } from "react";
import { useLocation  } from 'react-router-dom';

import james from "../../assets/about-us/James.png";
import peter from "../../assets/about-us/Peter_Kimani.png";
import hesbon from "../../assets/about-us/Hesbon_Kiura.png";
import francis from "../../assets/about-us/IMG_6707.png";
import AOS from "aos";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
const AboutTeam = () => {
//   useEffect(() => {
//     AOS.init({ duration: 2000 });
//   }, []);

  const location = useLocation();
  const targetId = location.hash.substring(1);

  useEffect(() => {
    AOS.init({ duration: 2000 });
    // Function to scroll to the target section
    const scrollToTargetSection = () => {
      const targetElement = document.getElementById(targetId);
      console.log(targetElement);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Call the function to scroll to the target section
    // Wait for the DOM to be ready before scrolling
    document.addEventListener('DOMContentLoaded', scrollToTargetSection);

    // Clean up the event listener to avoid memory leaks
    return () => {
      document.removeEventListener('DOMContentLoaded', scrollToTargetSection);
    };
  }, [targetId]);



  return (
    <main>
    <Header />
    <section class="bg-white dark:bg-gray-900 my-8 p-16">
         {/* James Kagoni. */}
   <div id="1" class="flex flex-wrap items-center rounded-[20px] bg-white p-10 shadow-xl mt-8">
      <div class="w-full shrink-0 grow-0 basis-auto md:px-6 lg:w-7/12">

      <div class="items-center justify-center mb-6">
    <h3 class="mb-2 block text-4xl font-lufgaBold text-[#F2B807]">James Kagoni.</h3>
    <div class="bg-[#F2B807] w-1/2 items-center border border-gray-100 rounded-tl-none rounded-br-none rounded-[10px]">
        <p class="text-white text-sm text-left p-2 font-lufga"><i>‘The key to successful leadership today is <br />influence, not authority.’</i></p>
    </div>
    <p class="font-lufgaBold text-2xl text-[#010440] mt-4">Chief Executive Officer</p>
    </div>
    <div className="border rounded-lg bg-[#F8F8F8] pl-10 shadow-lg">
        <div class="mb-4 flex mt-10">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">About</h3>
            <p class="font-lufga text-sm text-[#212121]">
            James Kagoni is a trained Investment Banker with a Bachelor of Commerce in Finance and is currently
            pursuing a Masters of Arts in Leadership at PAC University. He has served as an Investment Banker,
            Portfolio Manager and Investments Advisor with some of the leading investment banks as well as
            Stockbridge Investments where he managed portfolios of over 1.5 Billion Shillings with clear growth trajectory.
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">Career History</h3>
            <p class="font-lufga text-sm text-[#212121]">
            James is currently the Chief Executive Officer of Promitto where he steers the agenda on affordable
            housing as the market leader which currently runs a portfolio of more than 1 Billion worth of
            construction projects. This is after successfully restructuring the defunct Ekeza Sacco where he
            oversaw refunds of over Kshs. 1.2B of the affected members within a period of 1 year.
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">Achievements</h3>
            <p class="font-lufga text-sm text-[#212121]">
            He is also accredited as the founder of CNI IDEAS, an innovations hub that connects creative ideas
            with partnerships, mentors and seed funding having secured strategic partnerships with universities
            across the country including but not limited to UoN, Multimedia, Pioneer, MKU.
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
            <h3 class="mb-1 block text-lg font-lufgaBold text-black">Expertise</h3>
            <p class="font-lufga text-sm text-[#212121]">
            James Kagoni possesses a deep knowledge in economics and financial analysis, planning and
            development, leadership and is a remarkable public speaker. He is passionate about community
            service and politics with proficiency in English, Swahili and Kikuyu languages.
            </p>
          </div>
        </div>
        </div>

      </div>

      <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-6 lg:mb-0 lg:w-5/12">
        <div class="relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src={james} class="w-full" />
          <a href="#!">

            <div class="relative overflow-hidden bg-cover bg-no-repeat">
              <div
                class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    {/* Hesbon Kiura */}
   {/* <div class="flex flex-wrap items-center rounded-[20px] bg-white p-10 shadow-xl mt-8">
      <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-6 lg:mb-0 lg:w-5/12">
        <div class="relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src={hesbon} class="w-full" />
          <a href="#!">

            <div class="relative overflow-hidden bg-cover bg-no-repeat">
              <div
                class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
              </div>
            </div>
          </a>
        </div>
      </div>

      <div class="w-full shrink-0 grow-0 basis-auto md:px-6 lg:w-7/12">

  <div class="items-center justify-center ml-14 mb-6">
    <h3 class="mb-2 block text-4xl font-lufgaBold text-[#F2B807]">Hesbon Kiura.</h3>
    <p class="font-lufgaBold text-2xl text-[#010440]">Board Chairman</p>
    </div>
    <div className="border rounded-lg bg-[#F8F8F8] pl-10 shadow-lg">
        <div class="mb-4 flex mt-10">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">About</h3>
            <p class="font-lufga text-sm text-[#212121]">
            Hesbon Kiura has worked for 38 years serving the cooperative movement in various capacities at the
            District, County and National levels in relation to Co-operative Management in Kenya and is currently
            the Assistant Commissioner for Cooperative Development in Nairobi, Kenya.
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">Education</h3>
            <p class="font-lufga text-sm text-[#212121]">
            He is pursuing a PhD program in Entrepreneurship at Karatina University and holds a Master in
            Business Administration (MBA): with a specialization in Entrepreneurship, Minor in Marketing and a
            Bachelor of Business Administration (BBA) in Entrepreneurship from the Kenya Methodist University and
            a Diploma in Co-operative Management at the Co-operative College of Kenya.
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">Achievements</h3>
            <p class="font-lufga text-sm text-[#212121]">
            He is a full member of the Kenya Institute of Management and the Kenya Society of Professional Co-
            operators and has served as a consultant for the International Co-operative Alliance as the Kenya National Expert.
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
            <h3 class="mb-1 block text-lg font-lufgaBold text-black">Expertise</h3>
            <p class="font-lufga text-sm text-[#212121]">
            As the chairperson of the Board at Promitto, he brings on board a wealth of experience in Strategic
            planning, governance and leadership.
            </p>
          </div>
        </div>
        </div>

      </div>
    </div> */}

     {/* Peter Kimani */}
   <div class="flex flex-wrap items-center rounded-[20px] bg-white p-10 shadow-xl mt-8">
      <div class="w-full shrink-0 grow-0 basis-auto md:px-6 lg:w-7/12">

      <div class="items-center justify-center ml-14 mb-6">
    <h3 class="mb-2 block text-4xl font-lufgaBold text-[#F2B807]">Peter Kimani.</h3>
    <p class="font-lufgaBold text-2xl text-[#010440]">Advocate of the High Court</p>
    </div>
    <div className="border rounded-lg bg-[#F8F8F8] pl-10 shadow-lg">
        <div class="mb-4 flex mt-10">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">About</h3>
            <p class="font-lufga text-sm text-[#212121]">
            Peter Kimani is a seasoned advocate of the High Court of Kenya with 21 years of post-admission
            experience, commissioner for oaths and Notary Public, having attained a Bachelors of Laws Degree
            from the University of Nairobi and a Diploma in Law from the Kenya School of Law.
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">Career History</h3>
            <p class="font-lufga text-sm text-[#212121]">
            He is currently a Senior Partner in Kimani, Kiarie and Associates, a medium-sized law firm in Nairobi
            and as a Board Member of Promitto, he brings vast knowledge and experience in Corporate
            Governance and Legal and Policy Advisory to the Board.
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">Achievements</h3>
            <p class="font-lufga text-sm text-[#212121]">
            He has specialized training in Governance, Public Management and Economics in Singapore,
            Botswana, Dubai, Turkey and the USA respectively and holds a Masters of Law in Governance and
            Democracy from the University of Nairobi.
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
            <h3 class="mb-1 block text-lg font-lufgaBold text-black">Expertise</h3>
            <p class="font-lufga text-sm text-[#212121]">
            He has specialized training in Governance, Public Management and Economics in Singapore,
            Botswana, Dubai, Turkey and the USA respectively and holds a Masters of Law in Governance and
            Democracy from the University of Nairobi.
            </p>
          </div>
        </div>
        </div>

      </div>

      <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-6 lg:mb-0 lg:w-5/12">
        <div class="relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src={peter} class="w-full" />
          <a href="#!">

            <div class="relative overflow-hidden bg-cover bg-no-repeat">
              <div
                class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    {/* Francis Kamau. */}
   {/* <div class="flex flex-wrap items-center rounded-[20px] bg-white p-10 shadow-xl mt-8">
      <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-6 lg:mb-0 lg:w-5/12">
        <div class="relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
          data-te-ripple-init data-te-ripple-color="light">
          <img src={francis} class="w-full" />
          <a href="#!">

            <div class="relative overflow-hidden bg-cover bg-no-repeat">
              <div
                class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
              </div>
            </div>
          </a>
        </div>
      </div>

      <div class="w-full shrink-0 grow-0 basis-auto md:px-6 lg:w-7/12">

      <div class="items-center justify-center ml-14 mb-6">
    <h3 class="mb-2 block text-4xl font-lufgaBold text-[#F2B807]">Francis Kamau.</h3>
    <p class="font-lufgaBold text-2xl text-[#010440]">Chief Technical Officer</p>
    </div>
    <div className="border rounded-lg bg-[#F8F8F8] pl-10 shadow-lg">
        <div class="mb-4 flex mt-10">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">About</h3>
            <p class="font-lufga text-sm text-[#212121]">
            Francis Kamau is a technology professional with 13 years experience in Software Engineering. He is
            passionate about technology, disruptive trends and building products for the African consumer.
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">Education</h3>
            <p class="font-lufga text-sm text-[#212121]">
            He holds a Bachelors in Computer Science and is a finalist in the Growth Africa Business Accelerator 2020 Cohort
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
          <h3 class="mb-1 block text-lg font-lufgaBold text-black">Achievements</h3>
            <p class="font-lufga text-sm text-[#212121]">
            He currently runs a software company known as APLUS AFRICA that has brought to market multiple
            products for corporations across sub-Saharan Africa including USAID funded Intergovernmental
            Authority on Development (IGAD), Diageo, Oilcom (Tanzania), Cooperative Bank (Kenya), National
            Oil, Riara University, Tigo Telecommunications (Chad) amongst others. He has led different teams
            comprising of data analysts, software developers, field agents and has also managed various
            stakeholders under different capacities (Consultants, Investors, C Suite).
            </p>
          </div>
        </div>

        <div class="mb-4 flex">

          <div class="ml-4 grow">
            <h3 class="mb-1 block text-lg font-lufgaBold text-black">Expertise</h3>
            <p class="font-lufga text-sm text-[#212121]">
            As a board member of Promitto, Francis dvises and provides guidance and support on the
            technological needs, interests and processes of the company in addition to research and development
            </p>
          </div>
        </div>
        </div>

      </div>
    </div> */}

</section>
    <Footer />
    </main>
  );
};

export default AboutTeam;

import React, { useState, useEffect } from 'react';
import { Collapse, Carousel, initTE } from 'tw-elements';
import logo from '../assets/promitto_logo_transparent.png';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import MainContent from '../components/MainContent/MainContent';
import Statistics from '../components/Sections/Statistics';
import Explore from '../components/Sections/Explore';
import Services from '../components/Sections/Services';
import WhyChooseUs from '../components/Sections/WhyChooseUs';

const Test = () => {
  initTE({ Collapse, Carousel });

  useEffect(() => {
    // Add your useEffect logic here
  }, []);

  const navLinkStyles = ({ isActive }) => {
    return {
        fontWeight: isActive ? 'bold' : 'bold',
        textDecoration: 'none',
        color: isActive ? '#F2B807' : '#666666',
        borderBottom: isActive ? '3.5px solid #F2B807' : 'none',
        hover: '#F2B807',

    }
  }
  const mobileNavLinkStyles = ({ isActive }) => {
    return {
        fontWeight: isActive ? 'bold' : 'bold',
        textDecoration: 'none',
        color: isActive ? '#666666' : '#666666',
        hover: isActive ? '#F2B807' : '#666666',
        background: isActive ? '#F2B807' : 'none',
        backgroundColor: isActive ? '#F2B807' : 'none',
        border: isActive ? '10px' : 'none',
    }
  }
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    // Main Navigation
    <>
      <header>
        {/* Navbar */}
        <nav className="z-10 fixed top-0 flex-no-wrap flex w-full items-center justify-between bg-white border-gray-200 py-4 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10 lg:flex-wrap lg:justify-start lg:py-4">
          <div className="flex w-full flex-wrap items-center justify-between px-3">
            {/* Hamburger button for mobile view */}
            <button
            onClick={toggleMenu}
              class="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
              type="button"
              data-te-collapse-init
              data-te-target="#navbarSupportedContent4"
              aria-controls="navbarSupportedContent4"
              aria-expanded="false"
              aria-label="Toggle navigation">
              {/* <!-- Hamburger icon --> */}
              <span class="[&>svg]:w-7">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="h-7 w-7">
                  <path
                    fill-rule="evenodd"
                    d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </button>

            {/* Collapsible navigation container */}
            <div className="!visible hidden flex-grow basis-[100%] items-center lg:!flex lg:basis-auto" id="navbarSupportedContent1" data-te-collapse-item>
              {/* Logo */}
              <a className="mb-4 ml-2 mr-5 mt-3 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 lg:mb-0 lg:mt-0" href="#">
                <img src={logo} className='h-10 lg:h-12' alt="TE Logo" loading="lazy" />
              </a>

              {/* Left navigation links */}

              <div
      class="!visible mt-2 hidden flex-grow basis-[100%] items-center justify-center lg:mt-0 lg:!flex lg:basis-auto"
      id="navbarSupportedContent8"
      data-te-collapse-item>
      <ul
        class="list-style-none flex flex-col pl-0 lg:mt-1 lg:flex-row"
        data-te-navbar-nav-ref>

        <li
          className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-6"
          data-te-nav-item-ref>
          <NavLink to="/"
          className="transition ease-in-out delay-150 duration-300 block py-2 pl-3 pr-6 text-gray-500 font-lufga font-semibold border-b-[3px] border-white hover:text-[#F2B807] hover:border-[#F2B807] lg:p-0 dark:text-gray-400 dark:border-gray-700 lg:dark:hover:text-white lg:dark:hover:bg-transparent"
          >Home
          </NavLink>
        </li>
        <li
          className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-6"
          data-te-nav-item-ref>
          <NavLink to="#"
          className="transition ease-in-out delay-150 duration-300 block py-2 pl-3 pr-6 text-gray-500 font-lufga font-semibold border-b-[3px] border-white hover:text-[#F2B807] hover:border-[#F2B807] lg:p-0 dark:text-gray-400 dark:border-gray-700 lg:dark:hover:text-white lg:dark:hover:bg-transparent"
          >About Us
          </NavLink>
        </li>
        <li
          className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-6"
          data-te-nav-item-ref>
          <NavLink to="#"
          className="transition ease-in-out delay-150 duration-300 block py-2 pl-3 pr-6 text-gray-500 font-lufga font-semibold border-b-[3px] border-white hover:text-[#F2B807] hover:border-[#F2B807] lg:p-0 dark:text-gray-400 dark:border-gray-700 lg:dark:hover:text-white lg:dark:hover:bg-transparent"
          >Our Housing Projects
          </NavLink>
        </li>
        <li
          className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-6"
          data-te-nav-item-ref>
          <NavLink to="#"
          className="transition ease-in-out delay-150 duration-300 block py-2 pl-3 pr-6 text-gray-500 font-lufga font-semibold border-b-[3px] border-white hover:text-[#F2B807] hover:border-[#F2B807] lg:p-0 dark:text-gray-400 dark:border-gray-700 lg:dark:hover:text-white lg:dark:hover:bg-transparent"
          >How to Own
          </NavLink>
        </li>
        <li
          className="my-4 pl-2 lg:my-0 lg:pl-2 lg:pr-6"
          data-te-nav-item-ref>
          <NavLink to="#"
          className="transition ease-in-out delay-150 duration-300 block py-2 pl-3 pr-6 text-gray-500 font-lufga font-semibold border-b-[3px] border-white hover:text-[#F2B807] hover:border-[#F2B807] lg:p-0 dark:text-gray-400 dark:border-gray-700 lg:dark:hover:text-white lg:dark:hover:bg-transparent"
          >Contact Us
          </NavLink>
        </li>
      </ul>
    </div>
              {/* Right navigation button */}
              <div class="flex items-center">
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="mr-3 inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:bg-neutral-100 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 motion-reduce:transition-none">
                  Login
                </button>
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="mr-3 inline-block rounded bg-[#F2B807] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-gray shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#F2B807] hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#F2B807] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#F2B807] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] motion-reduce:transition-none dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                  Create Account
                </button>
              </div>
            </div>
          </div>
        </nav>
        {isMenuOpen && (


<div className={`items-center mx-4 justify-between w-3/4 z-10 bg-white border border-gray-200 rounded-xl -mt-4 lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu-2" data-aos="fade-down">
<ul className="flex flex-col mt-8 font-medium mx-2 mb-4">
  <li>
    <NavLink
      style={mobileNavLinkStyles}
      to="/"
      className="block py-2 pl-3 pr-6 text-gray-500 text-sm border-b font-lufga font-semibold border-gray-100 rounded-lg hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"
    >
      Home
    </NavLink>
  </li>
  <li>
    <NavLink
      style={mobileNavLinkStyles}
      to="/about-us"
      className="block py-2 pl-3 pr-6 text-gray-500 text-sm border-b font-lufga font-semibold border-gray-100 rounded-lg hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"
    >
      About Us
    </NavLink>
  </li>
  <li>
    <NavLink
      style={mobileNavLinkStyles}
      to="/our-housing-projects"
      className="block py-2 pl-3 pr-6 text-gray-500 text-sm border-b font-lufga font-semibold border-gray-100 rounded-lg hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"
    >
      Our Housing Projects
    </NavLink>
  </li>
  <li>
    <NavLink
      style={mobileNavLinkStyles}
      to="/how-to-own"
      className="block py-2 pl-3 pr-6 text-gray-500 text-sm border-b font-lufga font-semibold border-gray-100 rounded-lg hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"
    >
      How To Own
    </NavLink>
  </li>
  <li>
    <NavLink
      style={mobileNavLinkStyles}
      to="/contact-us"
      className="block py-2 pl-3 pr-6 text-gray-500 text-sm border-y font-lufga font-semibold border-gray-100 rounded-lg hover:bg-[#F2B807] hover:text-white dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700"
    >
      Contact Us
    </NavLink>
  </li>
</ul>
</div>
      )}
      </header>
      <MainContent />
      <Statistics />
      <Explore />
      <Services />
      <WhyChooseUs />
      </>
  );
}

export default Test;

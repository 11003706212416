import React, {useEffect, useState} from "react";
import axios from 'axios';
import AdminHeader from "./Components/AdminHeader";
import { useParams, useNavigate, Link } from "react-router-dom";
import Aside from "./Components/Aside";
import { BASE_URL} from '../../config';
import { FaImages, FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import {Modal,Ripple,initTE,} from "tw-elements";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ManageProjects = () =>{
  const [listingTypes, setListingTypes] = useState([]);
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(5);
    const [totalData, setTotalData] = useState(0);
    const [type, settype] = useState('');
    const [price, setprice] = useState('');
    const [deposit, setdeposit] = useState('');
    const [installments, setinstallments] = useState('');
    const [size, setsize] = useState('');
    const [salerent, setsalerent] = useState('');
    const [bedrooms, setbedrooms] = useState('');

//Manger Registration
const resetForm = () =>{
  settype('');
  setprice('');
  setdeposit('');
  setinstallments('');
  setsize('');
  setsalerent('');
  setbedrooms('');
}
const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { type, price, deposit, installments, size, salerent, bedrooms };

    try {
      fetch(`${BASE_URL}/api/registerProject`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      }).then((response) => response.json())
            .then((data) => {
              console.log(data);
              if (data.Status === "Success") {
                resetForm();
                toast.success(data.Message, {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  });
              } else {
                alert("Registration failed. Try again.");
              }
            })
            .catch((error) => console.log(error));
    } catch (error) {
      console.error('Error:', error);
    }

    // try {
    //   const response = await axios.post(`${BASE_URL}/api/registerProject`, formData);
    //   console.log('Response:', response.data);
    // } catch (error) {
    //   console.error('Error:', error);
    // }
  };

  const deleteListing = async (id) => {
    // Display confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete this listing?");

    // If user confirms deletion, proceed with fetch request
    if (confirmDelete) {
      try {
        const response = await fetch(`${BASE_URL}/api/deleteListing/${id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();
        console.log(data);
        getListingTypes();
        fetch(`${BASE_URL}/api/getListings`)
            .then((response) => response.json())
            .then((data) => {
              if (data.Status === "Success") {
                console.log(data.Projects);
                setProjects(data.Projects);
                setFilteredProjects(data.Projects);
                setTotalData(data.Projects.length);
              } else {
                //alert("Error");
              }
            })
            .catch((error) => console.log(error));

        if (data.Status === "Success") {
          toast.success(data.Message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        } else {
          alert(data.Message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }


  const getListingTypes = ()=>{
     // Fetch listing types
     fetch(`${BASE_URL}/api/getListingTypes`)
     .then((response) => response.json())
     .then((data) => {
       if (data.Status === "Success") {
         setListingTypes(data.ListingTpes);
       } else {
         // Handle error
       }
     })
     .catch((error) => console.log(error));
  }

    useEffect(() => {
      getListingTypes();
      initTE({ Modal, Ripple });
        const token = localStorage.getItem('token');

        if (token) {
          const headers = {
            authorization: `Bearer ${token}`,
          };

          fetch(`${BASE_URL}/api/getListings`)
            .then((response) => response.json())
            .then((data) => {
              if (data.Status === "Success") {
                console.log(data.Projects);
                setProjects(data.Projects);
                setFilteredProjects(data.Projects);
                setTotalData(data.Projects.length);
              } else {
                //alert("Error");
              }
            })
            .catch((error) => console.log(error));
        }
      }, []);


  const totalPages = Math.ceil(filteredProjects.length / dataPerPage);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredProjects.slice(indexOfFirstData, indexOfLastData);

  const filterProjects = (event) => {
    const searchValue = event.target.value.toLowerCase();
    if (searchValue === "") {
      setFilteredProjects(projects);
    } else {
      const filtered = projects.filter((project) => {
        return (
          project.type.toLowerCase().includes(searchValue) ||
          project.price.toLocaleString().includes(searchValue) ||
          project.deposit.toLocaleString().includes(searchValue) ||
          project.monthlyInstallments.toLocaleString().includes(searchValue) ||
          project.size.toString().includes(searchValue) ||
          project.salerent.toLowerCase().includes(searchValue) ||
          project.bedrooms.toString().includes(searchValue)
        );
      });
      setFilteredProjects(filtered);
    }
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };



  return (
    <section>
      <div>
        <AdminHeader />
        <div className="flex overflow-hidden bg-white pt-16">
          <Aside />
          <div
            className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
            id="sidebarBackdrop"
          ></div>
          <div
            id="main-content"
            className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
            <main>
              <div className="pt-6 px-4">
              <button
            type="button"
            class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            data-te-toggle="modal"
            data-te-target="#product"
            data-te-ripple-init
            data-te-ripple-color="light">
            Create Product
        </button>

        <div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="product"
  tabindex="-1"
  aria-labelledby="exampleModalLgLabel"
  aria-modal="true"
  role="dialog">
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]">
    <div
      class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
      <div
        class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

        <h5
          class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
          id="exampleModalLgLabel">
          Add Product
        </h5>
        <button
          type="button"
          class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss
          aria-label="Close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="relative p-4">
      <form className="mt-10">
                  <div class="grid gap-6 mb-6 md:grid-cols-3">

                  <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                          Type. <span className="text-[#F2B807]">*</span>
                        </label>

                        <select
                            id="type"
                            value={type}
                            onChange={(e) => settype(e.target.value)}
                            className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40"
                            name="type"
                            required
                          >
                            <option className="hover:bg-[#F2B807]" value="" disabled>
                              --Select a product type--
                            </option>
                            {listingTypes.map((listing) => (
                              <option key={listing.ID} value={listing.type}>
                                {listing.type}
                              </option>

                            ))}
                          </select>
                  </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Price. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="price"
                        value={price}
                        onChange={(e) => setprice(e.target.value)}
                        id="price" type="number" min={1}
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Deposit. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="deposit"
                        value={deposit}
                        onChange={(e) => setdeposit(e.target.value)}
                        id="deposit" type="number" min={1}
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Monthly Installments. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="installments"
                        value={installments}
                        onChange={(e) => setinstallments(e.target.value)}
                        id="installments" type="number" min={1}
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>

                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Size in Sqr Meters. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="size"
                        value={size}
                        onChange={(e) => setsize(e.target.value)}
                        id="size" type="number" min={1}
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        For Sale or Rent <span className="text-[#F2B807]">*</span>
                        </label>
                        <select
                        id="salerent"
                        value={salerent}
                        onChange={(e) => setsalerent(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-[#F2B807] focus:border-[#F2B807] block w-full px-4 py-2.5 mt-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-[#F2B807] dark:focus:border-[#F2B807]"
                      >
                        <option disabled value="">
                          --Select an Option--
                        </option>
                        <option value="sale">SALE</option>
                        <option value="rent">RENT</option>
                      </select>

                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Bedrooms <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="bedrooms"
                        value={bedrooms}
                        onChange={(e) => setbedrooms(e.target.value)}
                        id="bedrooms" type="number" min={1}
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>

                  </div>
                  <button
                  type="button"
                  onClick={handleSubmit}
                      class="inline-block rounded bg-warning px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                      data-te-ripple-color="light">
                      Save
                  </button>

      </form>
      </div>
    </div>
  </div>
</div>
            {/* table */}

     <div class="flex flex-col mt-6">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

            <div className="relative mb-10 w-full flex items-center justify-between rounded-md">
              <input
                onChange={filterProjects}
                type="text"
                name="search"
                className="h-12 w-full cursor-text rounded-md border border-gray-100 bg-gray-100 py-4 pr-40 pl-12 shadow-sm outline-none focus:border-[#F2B807] focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search"
              />
            </div>
                <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Project Type</th>


                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Price</th>

                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Deposit</th>




                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Monthly Installments</th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Size</th>
                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Option</th>
                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Bedrooms</th>
                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Action</th>

                                {/* <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th> */}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                            {filteredProjects.length === 0 ? (
                            <tr>
                                <td
                                colSpan="9"
                                className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                                >
                                No data found
                                </td>
                            </tr>
                            ) : (
                                currentData.map((project, index) => (
                                <tr key={index}>
                                    <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{project.type}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{project.price.toLocaleString()}</td>

                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{project.deposit.toLocaleString()}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{project.monthlyInstallments.toLocaleString()}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{project.size} Sqr. Meters</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{project.salerent}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{project.bedrooms}</td>

                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                <div class="flex items-center gap-x-4">

                                                <MdDelete onClick={() => deleteListing(project.ID)} className="text-lg hover:text-yellow-500 hover:cursor-pointer" />

                                                    <Link to={`/edit-product/${project.ID}`}>
                                                      <FaEdit className="text-lg hover:text-yellow-500 hover:cursor-pointer" />
                                                    </Link>
                                                    </div>
                                            </td>
                                </tr>
                            ))
                            )}
                        </tbody>

                    </table>

                </div>
                  {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <button
        onClick={prevPage}
        disabled={currentPage === 1}
        className="bg-[#F2B807] text-black py-1.5 px-2 rounded disabled:opacity-50">
        <div class="flex items-center -mx-1">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-1 rtl:-scale-x-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>

            <span class="mx-1">
                previous
            </span>
        </div>
    </button>

        <div className="text-gray-600">
          Page <a href="#" class="inline-flex items-center justify-center px-4 py-1 mx-2 text-gray-700 transition-colors duration-300 transform bg-[#F2B807] rounded-lg dark:text-white dark:bg-gray-700">
          {currentPage}
            </a>
        of <a href="#" class="inline-flex items-center justify-center px-4 py-1 mx-2 text-gray-700 transition-colors duration-300 transform bg-gray-100 rounded-lg dark:text-white dark:bg-gray-700">
          {totalPages}
            </a>
        </div>

        <button
        onClick={nextPage}
        disabled={currentPage === totalPages}
         className="bg-[#F2B807] text-black py-1.5 px-2 rounded disabled:opacity-50">
        <div class="flex items-center -mx-1">
            <span class="mx-1">
                Next
            </span>

            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-1 rtl:-scale-x-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
        </div>
    </button>
      </div>

      <div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="listing"
  tabindex="-1"
  aria-labelledby="exampleModalXlLabel"
  aria-modal="true"
  role="dialog">
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]">
    <div
      class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
      <div
        class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

        <h5
          class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
          id="exampleModalXlLabel">
          Extra large modal
        </h5>
        <button
          type="button"
          class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss
          aria-label="Close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="relative p-4">...</div>
    </div>
  </div>
</div>

            </div>
        </div>
    </div>
              </div>
            </main>
            <p className="text-center text-sm text-gray-500 my-10">
              &copy; 2023{" "}
              <a
                href="https://eldowas.com"
                className="hover:underline"
                target="_blank"
              >
                Promitto Ltd
              </a>
              . All rights reserved.
            </p>
          </div>
        </div>
        <script async defer src="https://buttons.github.io/buttons.js"></script>
        <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
      </div>
    </section>
  );
}

export default ManageProjects;
import { useEffect } from 'react';
import Aos from 'aos';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import NoPage from './pages/NoPage';
import HousingProjects from './pages/HousingProjects';
import HowToOwn from './pages/HowToOwn';
import Register from './pages/Register';
import AboutTeam from './components/AboutUs/AboutTeam';
import Login from './pages/Login';
import OTP from './pages/Otp';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Account from './pages/Account';
import AdminLogin from './pages/AdminLogin';
import ProtectedRoutes from './pages/ProtectedRoutes';
import SideNav from './pages/Admin/SideNav';
import AboutProject from './components/HousingProjects/AboutProject';
import Test from './pages/Test';
import Dashboard from './pages/Dashboard';
import ClientProfile from './pages/Admin/ClientProfile';
import Test3 from './pages/Test3';
import Transactions from './pages/Admin/Transactions';
import Clients from './pages/Admin/Clients';
import Managers from './pages/Admin/Managers';
import AdminDashboard from './pages/Admin/AdminDashboard';
import ManageProjects from './pages/Admin/ManageProjects';
import ResetPassword from './pages/ResetPassword';
import ClientProject from './pages/Admin/ClientProject';
import ForgotPassword from './pages/ForgotPassword';
import ContactUs from './pages/ContactUs';
import TestAdmin from './pages/Test2';
import ProjectImages from './pages/Admin/ClientProjectsComponents/ProjectImages';
import DrawingsFilesPage from './pages/Admin/ClientProjectsComponents/DrawingsFilesPage';
import BQFilesPage from './pages/Admin/ClientProjectsComponents/BQFilesPage';
import TestRegister from './pages/TestRegister';
import Registration from './pages/Registration';
import RegistrationDocuments from './pages/Admin/RegistrationDocuments';
import Testimonials from './pages/Testimonials';
import EditProduct from './pages/Admin/EditProduct';

function App() {
  useEffect(() => {
    Aos.init();
  }, []);


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-housing-projects" element={<HousingProjects />} />
        <Route path="/how-to-own" element={<HowToOwn />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sidenav" element={<SideNav />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/test-admin" element={<TestAdmin />} />
        <Route path="/test3" element={<Test3 />} />
        <Route path="/about-project/:id" element={<AboutProject />} />
        <Route path="/project-images/:id" element={<ProjectImages />} />
        <Route path="/drawings/:id" element={<DrawingsFilesPage />} />
        <Route path="/bq-files/:id" element={<BQFilesPage />} />

        <Route path="/transactions" element={<Transactions />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/managers" element={<Managers />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/registration" element={<Registration />} />
        <Route path="/test" element={<Test />} />
        <Route path="/test-register" element={<TestRegister />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/project-management" element={<ManageProjects />} />
        <Route path="/edit-product/:id" element={<EditProduct />} />

        <Route path="/client-project/:id" element={<ClientProject />} />
        <Route path="/registered-documents/:id" element={<RegistrationDocuments />} />

        {/* <Route path="/account" element={<Account />} /> */}
        <Route path="/about-team/:id" element={<AboutTeam />} />
        <Route element={<ProtectedRoutes />}>
            <Route path="/account" element={<Account />} />

            <Route path="/client-profile/:id" element={<ClientProfile />} />
            <Route path="/dashboard" element={<Dashboard />} />


        </Route>


        {/* <Route path="/build/:bID" element={<Build />} />
        <Route path="/analytics/:aID" element={<Analytics />} /> */}
        <Route path="*" element={<NoPage />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}


export default App;

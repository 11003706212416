import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminHeader from "./Components/AdminHeader";
import Aside from "./Components/Aside";
import axios from "axios";
import { BASE_URL} from '../../config';
const ClientProfile = () => {
  const navigate = useNavigate();
    const { id } = useParams();
    const [options, setOptions] = useState([]);
    const [selectedManager, setSelectedManager] = useState(null);
    const [data, setData] =useState({
        full_name: '',
        email: '',
        national_id: '',
        dob:'',
        kra_pin: '',
        address: '',
        postal_code: '',
        country: '',
        city: '',
        county: '',
        phone_number: '',
        email: '',
        kin_name: '',
        kin_relationship: '',
        kin_dob: '',
        kin_phone: '',
        kin_national_id: '',
    })
    const GetManagers = () =>{
        fetch(`${BASE_URL}/api/getAdmins`)
        .then((response) => response.json())
        .then((data) => {
          if (data.Status === "Success") {
            setOptions(data.Managers);
          } else {
            //alert("Error");
            console.log(data.Message);
          }
        })
        .catch((error) => console.log(error));
      }
    useEffect(() => {
      GetManagers();
      fetch(`${BASE_URL}/api/getUserProfile/${id}`)
        .then(response => response.json())
        .then((data) => {
          if (data.Status === "Success") {
            setData({...data, full_name: data.Profile[0].FullName, gender: data.Profile[0].Gender, national_id: data.Profile[0].NationalID, dob: data.Profile[0].DateOfBirth, kra_pin: data.Profile[0].KRAPin, address: data.Profile[0].Address, postal_code: data.Profile[0].PostalCode, country: data.Profile[0].Country, city: data.Profile[0].City, county: data.Profile[0].County,
                phone_number: data.Profile[0].PhoneNumber, email: data.Profile[0].Email, kin_name: data.Profile[0].KinName, kin_relationship: data.Profile[0].KinRelationship, kin_dob: data.Profile[0].KinDOB, kin_phone: data.Profile[0].KinPhone, kin_national_id: data.Profile[0].KinNationalID})
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error));
    }, [id]);

    const assignManager = async (event) => {
      event.preventDefault();
        if (!selectedManager) {
            alert('Please select a manager.');
            return;
        }
        const { ID, FullName, Email, PhoneNumber } = selectedManager;
        //alert(`Selected Manager: ID=${ID}, FullName=${FullName}, Email=${Email}, Phone=${PhoneNumber}`);


        var manager = {
          "manager_id": ID,
          "manager_name": FullName,
          "manager_phone": PhoneNumber,
          "manager_email": Email
        }

        try {
          await fetch(`${BASE_URL}/api/assignManager/${id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(manager),
          }).then(response => response.json())
          .then((data) =>{
            if (data.Status === "Success"){
              alert(data.Message);
            }else{
              alert("Something went wrong while assigning a manager")
            }
          });

        } catch (error) {
          console.error('Error updating user:', error);
        }


    };

    const submitEditedDetails = async (event) => {
      event.preventDefault();

      await axios.put(`${BASE_URL}/api/editClientDetais`, data)
      .then(res =>{
        if(res.data === "Success"){
          alert(res.data.Message)
          navigate('/clients')
        }else{
          alert(res.data.Error)
        }

        console.log(res)
      })
      .catch(err => console.log(err));

    }


    return (
      <section>
        <div>
          <AdminHeader />
          <div className="flex overflow-hidden bg-white pt-16">
            <Aside />
            <div
              className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
              id="sidebarBackdrop"
            ></div>
            <div
              id="main-content"
              className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
            >
              <main>
                <div className="pt-6 px-4">
                <div className="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
                  <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
                     <div className="flex items-center justify-between mb-4">
                        <div className="flex-shrink-0">
                           <h3 className="text-xl font-bold text-gray-900 mb-2">Edit Client Account settings</h3>
                        </div>

                     </div>
                     <div class="flex flex-col max-w-[800px] max-h-full p-6 text-left text-gray-900 col-span-2">

                    <form onSubmit={submitEditedDetails}>
                      <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="username"
                          >
                            Full Name
                          </label>
                          <input
                            value={data.full_name}
                            onChange={e => setData({...data, full_name: e.target.value})}
                            id="username"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>
                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="username"
                          >
                            Email
                          </label>
                          <input
                          value={data.email}
                          onChange={e => setData({...data, email: e.target.value})}
                            id="username"
                            type="email"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="emailAddress"
                          >
                            Nationa ID/ Passport No.
                          </label>
                          <input
                          value={data.national_id}
                          onChange={e => setData({...data, national_id: e.target.value})}
                            id="emailAddress"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>
                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="emailAddress"
                          >
                            Phone Number
                          </label>
                          <input
                          value={data.phone_number}
                          onChange={e => setData({...data, phone_number: e.target.value})}
                            id="emailAddress"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="username"
                          >
                             Gender
                          </label>
                          <input
                            value={data.gender}
                            onChange={e => setData({...data, gender: e.target.value})}
                            id="username"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>
                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="username"
                          >
                            Date Of Birth
                          </label>
                          <input
                          value={data.dob}
                          onChange={e => setData({...data, dob: e.target.value})}
                            id="username"
                            type="date"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="emailAddress"
                          >
                            KRA PIN
                          </label>
                          <input
                          value={data.kra_pin}
                          onChange={e => setData({...data, kra_pin: e.target.value})}
                            id="emailAddress"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="username"
                          >
                            Address
                          </label>
                          <input
                          value={data.address}
                          onChange={e => setData({...data, address: e.target.value})}
                            id="username"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="emailAddress"
                          >
                            Postal Code
                          </label>
                          <input
                          value={data.postal_code}
                          onChange={e => setData({...data, postal_code: e.target.value})}
                            id="emailAddress"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="username"
                          >
                            Country
                          </label>
                          <input
                          value={data.country}
                          onChange={e => setData({...data, country: e.target.value})}
                            id="username"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="emailAddress"
                          >
                            City
                          </label>
                          <input
                          value={data.city}
                          onChange={e => setData({...data, city: e.target.value})}
                            id="emailAddress"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="username"
                          >
                            County
                          </label>
                          <input
                          value={data.county}
                          onChange={e => setData({...data, county: e.target.value})}
                            id="username"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="emailAddress"
                          >
                            Kin Name
                          </label>
                          <input
                          value={data.kin_name}
                          onChange={e => setData({...data, kin_name: e.target.value})}
                            id="emailAddress"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>
                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="username"
                          >
                            Kin Relationship
                          </label>
                          <input
                          value={data.kin_relationship}
                          onChange={e => setData({...data, kin_relationship: e.target.value})}
                            id="username"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="emailAddress"
                          >
                            Kin Date of Birth
                          </label>
                          <input
                          value={data.kin_dob}
                          onChange={e => setData({...data, kin_dob: e.target.value})}
                            id="emailAddress"
                            type="date"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>
                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="username"
                          >
                            Kin Phone
                          </label>
                          <input
                          value={data.kin_phone}
                          onChange={e => setData({...data, kin_phone: e.target.value})}
                            id="username"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>
                        <div>
                          <label
                            class="text-gray-700 dark:text-gray-200"
                            for="username"
                          >
                            Kin National ID
                          </label>
                          <input
                          value={data.kin_national_id}
                          onChange={e => setData({...data, kin_national_id: e.target.value})}
                            id="username"
                            type="text"
                            class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                          />
                        </div>

                      </div>

                      <div class="flex justify-end mt-6">
                        <button disabled type="submit" className="cursor-not-allowed px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">
                          Save
                        </button>
                      </div>
                    </form>
                </div>
                  </div>
                  <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
                     <div className="mb-4 flex items-center justify-between">
                        <div>
                           <h3 className="text-xl font-bold text-gray-900 mb-2">Assign Manager to a Client</h3>
                        </div>
                        <div className="flex-shrink-0">
                           <a href="#" className="text-sm font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2">View all</a>
                        </div>
                     </div>
                     <div className="flex flex-col mt-8">
                        <div className="overflow-x-auto rounded-lg">
                           <div className="align-middle inline-block min-w-full">

                           <form onSubmit={assignManager}>
                      <div class="grid grid-cols-1 mt-4 sm:grid-cols-1">
                      <div class="col-span-1 flex flex-col">
                        <label class="text-sm font-semibold text-gray-500" for="">Assign Manager</label>
                        {/* <select
                        id="selectedOption"
                        value={selectedOption} onChange={e => setSelectedOption(e.target.value)}
                         class="rounded-lg border px-2 py-2 shadow-sm outline-none focus:ring-[#F2B807]" name="">
                            <option className="hover:bg-[#F2B807]" value="" disabled>--Select a manager--</option>
                                {options.map(option => (
                                    <option key={option.ID} value={option.ID}>{option.FullName} - {option.Email}</option>
                                ))}
                        </select> */}
                        <select
                            id="selectedOption"
                            value={selectedManager ? selectedManager.ID : ''} // Set the value to the selected manager's ID
                            onChange={(e) => {
                              // Find the selected manager object based on the selected option value
                              const selectedOptionValue = e.target.value;
                              const selectedManagerObject = options.find((option) => option.ID === selectedOptionValue);
                              setSelectedManager(selectedManagerObject);
                            }}
                            className="rounded-lg border px-2 py-2 shadow-sm outline-none focus:ring-[#F2B807]"
                            name=""
                          >
                            <option className="hover:bg-[#F2B807]" value="" disabled>
                              --Select a manager--
                            </option>
                            {options.map((option) => (
                              <option key={option.ID} value={option.ID}>
                                {option.FullName} - {option.Email}
                              </option>
                            ))}
                          </select>
                        </div>




                      </div>

                      <div class="flex justify-end mt-6">
                        <button type="submit" class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">
                          Save
                        </button>
                      </div>
                    </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
                </div>
              </main>
              <p className="text-center text-sm text-gray-500 my-10">
                &copy; 2023{" "}
                <a
                  href="https://promitto.com"
                  className="hover:underline"
                  target="_blank"
                >
                  Promitto
                </a>
                . All rights reserved.
              </p>
            </div>
          </div>
          <script async defer src="https://buttons.github.io/buttons.js"></script>
          <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
        </div>
      </section>
    );
};

export default ClientProfile;

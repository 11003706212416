import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../config';
import AdminHeader from '../Components/AdminHeader';
import Aside from '../Components/Aside';

const ProjectImages = () => {
  const { id } = useParams();
  const [projectInfo, setProjectInfo] = useState([]);
  const [projectImages, setProjectImages] = useState([]);

  const openImageInNewTab =(imageUrl) => {
    // Use window.open() to open the image in a new tab
    window.open(imageUrl, '_blank');
  }


  const getProjectDetails = () => {
    fetch(`${BASE_URL}/api/getClientProjectByClientId/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === 'Success') {
          if (data.ProjectDetails[0].project_images != null) {
            setProjectInfo(data.ProjectDetails);
            setProjectImages(JSON.parse(data.ProjectDetails[0].project_images))
          }
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
    getProjectDetails();

    const intervalId = setInterval(getProjectDetails, 2000);

    // Clean up the interval when the component unmounts.
    return () => clearInterval(intervalId);
  }, [id]);

  return (
    <section>
      <div>
        <AdminHeader />
        <div className="flex overflow-hidden bg-white pt-16">
          <Aside />
          <div
            className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
            id="sidebarBackdrop"
          ></div>
          <div
            id="main-content"
            className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
          >
            <main>

              <div className="pt-6 px-4">
                <div className="max-w-[1000px] -px-10 mt-6 mx-auto lg:-px-10">
                <div className='flex items-left my-8'>
                <p className='text-black font-lufgaBold text-[28px]'>Project Images</p>
            </div>
                  <div className="space-y-2 lg:grid lg:grid-cols-4 sm:gap-3 xl:gap-5 lg:space-y-0">
                  {projectImages?.length > 0 ? (
                        projectImages.map((name, index) => (
                            <div key={index} className="flex relative overflow-hidden rounded-[20px]">
                            <img
                                src={`${BASE_URL}/${name}`} // Replace with the actual URL of your image server
                                alt={name}
                                className="w-full"
                                // onClick={openImageInNewTab(`http://localhost:5000/${name}`)}
                            />
                            </div>
                        ))
                        ) : (
                        <p className='mt-8 font-lufgaBold'>No images available.</p>
                        )}
                  </div>
                </div>
              </div>

            </main>
            <p className="text-center text-sm text-gray-500 my-10">
              &copy; 2023 <a href="https://promittoltd.com" className="hover:underline" target="_blank">Promitto</a>. All rights reserved.
            </p>
          </div>
        </div>
        <script async defer src="https://buttons.github.io/buttons.js"></script>
        <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
      </div>
    </section>
  );
};

export default ProjectImages;

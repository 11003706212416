import React, { useEffect, useState } from "react";
import axios from "axios";
import {BASE_URL} from '../../config';

const Payments = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);


  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const headers = {
        authorization: `Bearer ${token}`,
      };

      fetch(`${BASE_URL}/api/payments`)
        .then((response) => response.json())
        .then((data) => {
          if (data.Status === "Success") {
            console.log(data.Transactions);
            setTransactions(data.Transactions);
            setFilteredTransactions(data.Transactions);
          } else {
            alert("Error");
          }
        })
        .catch((error) => console.log(error));
    }
  }, []);

  const filterTransactions = (event) => {
    const searchValue = event.target.value.toLowerCase();
    if (searchValue === "") {
      setFilteredTransactions(transactions);
    } else {
      const filtered = transactions.filter((transaction) => {
        return (
          transaction.MpesaReceiptNumber.toLowerCase().includes(searchValue) ||
          transaction.Id.toString().includes(searchValue) ||
          transaction.PhoneNumber.includes(searchValue) ||
          transaction.ClientID.includes(searchValue)
          // Add more conditions for other fields you want to search by
        );
      });
      setFilteredTransactions(filtered);
    }
  };

  return (
    <>
      <div className="flex flex-col mt-6">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative mb-10 w-full flex items-center justify-between rounded-md">
              <input
                onChange={filterTransactions}
                type="text"
                name="search"
                className="h-12 w-full cursor-text rounded-md border border-gray-100 bg-gray-100 py-4 pr-40 pl-12 shadow-sm outline-none focus:border-[#F2B807] focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search by Reference No., ID, Phone number, etc"
              />
            </div>

            <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">ID</th>
                    <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Reference No.</th>
                    <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Amount</th>
                    <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Phone Number</th>
                    <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">ID / Passport No.</th>
                    <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Transaction Date</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                  {filteredTransactions.length === 0 ? (
                    <tr>
                      <td
                        colSpan="6"
                        className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                      >
                        No data found
                      </td>
                    </tr>
                  ) : (
                    filteredTransactions.map((transaction, index) => (
                      <tr key={index}>
                        <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{transaction.Id}</td>
                        <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{transaction.MpesaReceiptNumber}</td>
                        <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{transaction.Amount.toLocaleString()}</td>
                        <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{transaction.PhoneNumber}</td>
                        <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{transaction.ClientID}</td>
                        <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{transaction.TransactionDate}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payments;

import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminHeader from "./Components/AdminHeader";
import Aside from "./Components/Aside";
import {BASE_URL} from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Modal,
  Ripple,
  initTE,
} from "tw-elements";



const Managers = () => {
    const [managers, setManagers] = useState([]);
    const [filteredManagers, setFilteredManagers] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [firstname, setFirstname] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [role, setRole] = useState('');
    const [title, setTitle] = useState('');
    const [nationalId, setNationalId] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(7);


    // const generateRandomPassword = () => {
    //     const randomPassword = generatePassword(); // Implement this function
    //     setPassword(randomPassword);
    //     setInputValue(randomPassword);
    //   };

      const generatePassword = () => {
        // Implement your password generation logic here
        // For simplicity, let's generate a random password of length 10
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = 10;
        let password = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          password += characters.charAt(randomIndex);
        }
        return password;
      };
//Manger Registration
const resetForm = () => {
  setFirstname('');
  setMiddlename('');
  setLastname('');
  setEmail('');
  setPhone('');
  setRole('');
  setTitle('');
  setNationalId('');
  // Reload the page
  //window.location.reload();
};
const handleSubmit = async (event) => {
    event.preventDefault();
    const fullname = firstname +" "+middlename+" "+lastname;
    const formData = { fullname, nationalId, phone, email, role, title };

    console.log(formData);
    try {
      fetch(`${BASE_URL}/api/registerAdmin`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      }).then((response) => response.json())
            .then((data) => {
              console.log(data);
              if (data.Status === "Success") {
                resetForm();
                toast.success("Upload was successfull", {
                  position: "top-right",
                  autoClose: 1000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  });
              } else {
                alert("Registration failed. Try again.");
              }
            })
            .catch((error) => console.log(error));
    } catch (error) {
      console.error('Error:', error);
    }
  };

    useEffect(() => {
      initTE({ Modal, Ripple });
        const token = localStorage.getItem('token');

        if (token) {
          const headers = {
            authorization: `Bearer ${token}`,
          };

          fetch(`${BASE_URL}/api/getAdmins`)
            .then((response) => response.json())
            .then((data) => {
              if (data.Status === "Success") {
                console.log(data.Managers);
                setManagers(data.Managers);
                setFilteredManagers(data.Managers);
              } else {
                alert("Error");
              }
            })
            .catch((error) => console.log(error));
        }
      }, []);

      const totalPages = Math.ceil(filteredManagers.length / dataPerPage);

      const indexOfLastData = currentPage * dataPerPage;
      const indexOfFirstData = indexOfLastData - dataPerPage;
      const currentData = filteredManagers.slice(indexOfFirstData, indexOfLastData);

      const filterManagers = (event) => {
        const searchValue = event.target.value ? event.target.value.toLowerCase() : '';
        if (searchValue === "") {
          setFilteredManagers(managers);
        } else {
          const filtered = managers.filter((manager) => {
            return (
              manager.FullName.toLowerCase().includes(searchValue) ||
              manager.NationalID.toString().includes(searchValue) ||
              manager.PhoneNumber.includes(searchValue) ||
              manager.Email.includes(searchValue)
              // Add more conditions for other fields you want to search by
            );
          });
          setFilteredManagers(filtered);
        }
        setCurrentPage(1);
      };

      const prevPage = () => {
        setCurrentPage(currentPage - 1);
      };

      const nextPage = () => {
        setCurrentPage(currentPage + 1);
      };
      return (
        <section>
          <div>
            <AdminHeader />
            <div className="flex overflow-hidden bg-white pt-16">
              <Aside />
              <div
                className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
                id="sidebarBackdrop"
              ></div>
              <div
                id="main-content"
                className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
              >
                <main>
                  <div className="pt-6 px-4">
                    <div className="flex flex-col mt-6">
                        <div class="flex flex-col mt-6">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">

            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

            <button
    type="button"
    class="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
    data-te-toggle="modal"
    data-te-target="#exampleModalLg"
    data-te-ripple-init
    data-te-ripple-color="light">
    Add Manager
  </button>
            <div className="relative mb-10 w-full flex items-center justify-between rounded-md">
              <input
                onChange={filterManagers}
                type="text"
                name="search"
                className="h-12 w-full cursor-text rounded-md border border-gray-100 bg-gray-100 py-4 pr-40 pl-12 shadow-sm outline-none focus:border-[#F2B807] focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search by details e.g phone, email, etc"
              />
            </div>

<div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="exampleModalLg"
  tabindex="-1"
  aria-labelledby="exampleModalLgLabel"
  aria-modal="true"
  role="dialog">
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]">
    <div
      class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
      <div
        class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

        <h5
          class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
          id="exampleModalLgLabel">
          Manager Details
        </h5>
        <button
          type="button"
          class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss
          aria-label="Close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="relative p-4">
      <form className="mt-10">
                  <div class="grid gap-6 mb-6 md:grid-cols-3">

                  <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                          First Name. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="firstname"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        id="firstname" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                  </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Middle Name. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="middlename"
                        value={middlename}
                        onChange={(e) => setMiddlename(e.target.value)}
                        id="middlename" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Last Name. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="lastname"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        id="lastname" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                    <div>
                    <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Role. <span className="text-[#F2B807]">*</span>
                        </label>
                      <select
                        id="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-[#F2B807] focus:border-[#F2B807] block w-full px-4 py-2.5 mt-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-[#F2B807] dark:focus:border-[#F2B807]"
                      >
                        <option disabled value="">
                          Select role
                        </option>
                        <option value="super">Super Admin</option>
                        <option value="admin">Administrator</option>
                        <option value="pmanager">Project Manager</option>
                        <option value="admin">Account Manager</option>
                      </select>
                    </div>
                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Phone Number. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        id="phone" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>

                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        National ID/ Passport No. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="nationalId"
                        value={nationalId}
                        onChange={(e) => setNationalId(e.target.value)}
                        id="nationalId" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Job Title <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        id="title" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Email <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email" type="email"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>

                  </div>
                  <button
                  type="button"
                  onClick={handleSubmit}
                      class="inline-block rounded bg-warning px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                      data-te-ripple-color="light">
                      Save
                  </button>

      </form>
      </div>
    </div>
  </div>
</div>
                <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Id</th>

                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Name</th>

                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Role</th>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Title</th>

                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">National ID</th>

                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Phone Number</th>




                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Email</th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Action</th>

                                {/* <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th> */}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {!filteredManagers.length ? (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                              >
                                No data found
                              </td>
                            </tr>
                          ) : (
                            currentData.map((manager, index) => (
                              <tr key={index}>
                                <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{manager.sys_id}</td>
                                <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{manager.FullName}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{manager.role}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{manager.managerTitle}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{manager.NationalID}</td>

                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{manager.PhoneNumber}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{manager.Email}</td>

                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                <div class="flex items-center gap-x-4">
                                                    <button class="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                        </svg>
                                                    </button>

                                                    <button class="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <button
        onClick={prevPage}
        disabled={currentPage === 1}
        className="bg-[#F2B807] text-black py-1.5 px-2 rounded disabled:opacity-50">
        <div class="flex items-center -mx-1">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-1 rtl:-scale-x-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>

            <span class="mx-1">
                previous
            </span>
        </div>
        </button>

        <div className="text-gray-600">
          Page <a href="#" class="cursor-not-allowed inline-flex items-center justify-center px-4 py-1 mx-2 text-gray-700 transition-colors duration-300 transform bg-[#F2B807] rounded-lg dark:text-white dark:bg-gray-700">
          {currentPage}
            </a>
        of <a href="#" class="cursor-not-allowed inline-flex items-center justify-center px-4 py-1 mx-2 text-gray-700 transition-colors duration-300 transform bg-gray-100 rounded-lg dark:text-white dark:bg-gray-700">
          {totalPages}
            </a>
        </div>

        <button
        onClick={nextPage}
        disabled={currentPage === totalPages}
         className="bg-[#F2B807] text-black py-1.5 px-2 rounded disabled:opacity-50">
        <div class="flex items-center -mx-1">
            <span class="mx-1">
                Next
            </span>

            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-1 rtl:-scale-x-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
        </div>
    </button>
      </div>
            </div>
        </div>
    </div>
                    </div>
                  </div>
                </main>
                <p className="text-center text-sm text-gray-500 my-10">
            &copy; 2023 <a href="https://promittoltd.com" className="hover:underline" target="_blank">Promitto</a>. All rights reserved.
         </p>
              </div>
            </div>
            <script async defer src="https://buttons.github.io/buttons.js"></script>
            <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
          </div>
        </section>
      );
};

export default Managers;

import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { MdMobileScreenShare } from "react-icons/md";
import { FiCheck } from "react-icons/fi";
import { AiTwotoneFileImage } from "react-icons/ai";
import axios from 'axios';

import MpesaSvg from "../assets/account/mpesap.png";
import VisaSvg from "../assets/account/visa.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { countries } from "./countries";

import { icons } from "react-icons";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import {
  TETabs,
  TETabsContent,
  TETabsItem,
  TETabsPane,
} from "tw-elements-react";
import { Modal, Ripple, initTE } from "tw-elements";
import LoanCalculator from "./LoanCalculator";
import Swal from "sweetalert2";
import { BASE_URL} from '../config';

const Register = () => {
  initTE({ Modal, Ripple });
  const [mpesaphone, setMpesaPhone] = useState('');
  const [mpesaACNo, setMpesaACNo] = useState('');
  const[checkoutId, setCheckOutId] = useState("");
  const [showmpesaCard, setShowmpesacard] = useState(true);
  const [showvisaCard, setShowvisacard] = useState(false);
  const [emailVisa, setEmailVisa] = useState('');
  const [amountVisa, setamountVisa] = useState('');
  const [currency, setCurrency] = useState('');

  const handleCurrencyChange = (e) => {
    e.preventDefault();
    setCurrency(e.target.value);
  };


  // Handle changes to the input field
  const handlePhoneChange = (e) => {
    setMpesaPhone(e.target.value);
  };

  const submitCardPayment = async (e) => {
    e.preventDefault();
    console.log(currency + " " + parseInt(amountVisa).toLocaleString());
    const email = localStorage.getItem("email");
    const phoneNumber = localStorage.getItem("phone");
    const fullName = localStorage.getItem("firstName") +" " +localStorage.getItem("middleName") +" " +localStorage.getItem("lastName");
    const message = currency + " " + parseInt(amountVisa).toLocaleString();

    let hasError = false; // Add a flag to track errors

    if (email == null || phoneNumber == null || fullName == null) {
      alert(
        "Check that you have your email, phone number, or full name filled from the personal information section"
      );
      hasError = true; // Set the error flag to true
    } else if (currency === "" || amountVisa === "") {
      alert("Please ensure you have selected a currency and entered an amount");
      hasError = true; // Set the error flag to true
    }

    if (!hasError) {
      const data = { fullName, email, phoneNumber, message };
      await fetch(`${BASE_URL}/api/request-invoice`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          credentials: "include",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.Status === "Success") {
            setCurrency("");
            setamountVisa("");
            toast.success(data.Message, {
              position: "top-center",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            alert("Failed to send the request. Try again or contact us");
          }
        });
    }
  };


  const handlemailVisaChange = (e) => {
    e.preventDefault();
    setEmailVisa(e.target.value);
  }
  const handleamountVisaChange = (e) => {
    e.preventDefault();
    setamountVisa(e.target.value);
  }

  const displayMpesaCard = () => {
    //event.preventDefault();
    setShowmpesacard(true);
    setShowvisacard(false);
  }


  const displayVisaCard = () => {
    //event.preventDefault();
    setShowmpesacard(false);
    setShowvisacard(true);
  }

   // Handle changes to the input field
   const handleIDChange = (e) => {
    setMpesaACNo(e.target.value);
  };
  const [listingTypes, setListingTypes] = useState([]);
  const [selectedProjectType, setSelectedProjectType] = useState('');
  const [projectDetails, setProjectDetails] = useState('');

  const handleProjectTypeChange = (e) => {
    setSelectedProjectType(e.target.value);
  };

  const handleProjectDetailsChange = (e) =>{
    setProjectDetails(e.target.value);
  }

  const handleSubmitProjectDetails = (e) => {
    e.preventDefault();
    if(selectedProjectType == ''){
      alert("Please Select a project Type")
      return;
    }
    else if(projectDetails == ''){
      alert("Please give your project description");
    }else{
      localStorage.setItem("projectType", selectedProjectType);
      localStorage.setItem("projectDetails", projectDetails);
      handleJustifyClick("tab3");
    }

  };
  useEffect(() => {
    initTE({ Modal, Ripple });

        // Fetch listing types
        fetch(`${BASE_URL}/api/getListingTypes`)
        .then((response) => response.json())
        .then((data) => {
          if (data.Status === "Success") {
            console.log(data.ListingTpes);
            setListingTypes(data.ListingTpes);
          } else {
            // Handle error
          }
        })
        .catch((error) => console.log(error));
    setShowmpesacard(true);
    setShowvisacard(false);
    const phoneFromLocalStorage = localStorage.getItem('phone');
    const idFromLocalStorage = localStorage.getItem('id');

    if (phoneFromLocalStorage) {
      setMpesaPhone(phoneFromLocalStorage);
    }

    if (idFromLocalStorage) {
      setMpesaACNo(idFromLocalStorage);
    }

    const storedPhone = localStorage.getItem('phone');
    const identityno = localStorage.getItem('id');
    if (storedPhone && identityno) {
      setMpesaPhone(storedPhone);
      setMpesaACNo(identityno);
    }
  },[]);
  let navigate = useNavigate();





  const [loading, setLoading] = useState(false);
  const [justifyActive, setJustifyActive] = useState("tab1");
  const [showButton, setShowButton] = useState(false);

  const [firstName, setfirstName] = useState("");
  const [middleName, setmiddleName] = useState("");
  const [lastName, setlastName] = useState("");
  const [gender, setgender] = useState("");
  const [dob, setdob] = useState("");
  const [id, setid] = useState("");
  const [krapin, setkrapin] = useState("");
  const [address, setaddress] = useState("");
  const [postalcode, setpostalcode] = useState("");
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const [county, setcounty] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");

  const [kinfirstName, setkinfirstName] = useState("");
  const [kinmiddleName, setkinmiddleName] = useState("");
  const [kinlastName, setkinlastName] = useState("");
  const [kinrelationship, setkinrelationship] = useState("");
  const [kinphone, setkinphone] = useState("");
  const [kinid, setkinid] = useState("");
  const [kindob, setkindob] = useState("");

  const handleSubmitKinDetails = (e) => {

    e.preventDefault();
    localStorage.setItem("kinfirstName", kinfirstName);
    localStorage.setItem("kinmiddleName", kinmiddleName);
    localStorage.setItem("kinlastName", kinlastName);
    localStorage.setItem("kinrelationship", kinrelationship);
    localStorage.setItem("kinphone", kinphone);
    localStorage.setItem("kinid", kinid);
    localStorage.setItem("kindob", kindob);
    handleJustifyClick("tab5");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //window.localStorage.setItem('formData', JSON.stringify(formData));
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("middleName", middleName);
    localStorage.setItem("lastName", lastName);
    localStorage.setItem("email", email);
    localStorage.setItem("id", id);
    localStorage.setItem("dob", dob);
    localStorage.setItem("krapin", krapin);
    localStorage.setItem("gender", gender);
    localStorage.setItem("address", address);
    localStorage.setItem("postalcode", postalcode);
    localStorage.setItem("country", country);
    localStorage.setItem("county", county);
    localStorage.setItem("city", city);
    localStorage.setItem("phone", phone);

    if (selectedKraFile) {
      localStorage.setItem("selectedKraFileName", selectedKraFile.name);
      //localStorage.setItem("selectedKraFile", selectedKraFile);
    }

    if (selectedIDFile) {
      localStorage.setItem("selectedIDFileName", selectedIDFile.name);
      //localStorage.setItem("selectedIDFile", selectedIDFile);
    }

    handleJustifyClick("tab4");
  };

  const [selectedKraFile, setSelectedKraFile] = useState(null);
  const [selectedIDFile, setSelectedIDFile] = useState(null);
  const [selectedTitleDeedFile, setSelectedTitleDeedFile] = useState(null);
  const [selectedPassportPhotoFile, setSelectedPassportPhotoFile] = useState(null);

  const handleIDFileChange = (event) => {
    setSelectedIDFile(event.target.files[0]);
  };

  const handleKraFileChange = (event) => {
    setSelectedKraFile(event.target.files[0]);
  };
  const handleTitleDeedFileChange = (event) => {
    setSelectedTitleDeedFile(event.target.files[0]);
  };

  const handlePassportPhotoFileChange = (event) => {
    setSelectedPassportPhotoFile(event.target.files[0]);
  };

  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }
    setJustifyActive(value);
  };

  const postUser = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('full_name', localStorage.getItem("firstName") + " " + localStorage.getItem("middleName") + " " + localStorage.getItem("lastName"));
    formData.append('gender', localStorage.getItem("gender"));
    // ... append other form fields

    formData.append('dob', localStorage.getItem("dob"));
    formData.append('national_id', localStorage.getItem("id"));
    formData.append('krapin', localStorage.getItem("krapin"));
    formData.append('address', localStorage.getItem("address"));
    formData.append('postalcode', localStorage.getItem("postalcode"));
    formData.append('country', localStorage.getItem("country"));
    formData.append('city', localStorage.getItem("city"));
    formData.append('county', localStorage.getItem("county"));
    formData.append('phone', localStorage.getItem("phone"));
    formData.append('email', localStorage.getItem("email"));
    formData.append('kin_name', localStorage.getItem("kinfirstName") + " " + localStorage.getItem("kinmiddleName") + " " + localStorage.getItem("kinlastName"));
    formData.append('kinrelationship', localStorage.getItem("kinrelationship"));
    formData.append('kindob', localStorage.getItem("kindob"));
    formData.append('kinphone', localStorage.getItem("kinphone"));
    formData.append('kinid', localStorage.getItem("kinid"));

    formData.append('idfile', selectedIDFile);
    formData.append('krafile', selectedKraFile);
    formData.append('titlefile', selectedTitleDeedFile);
    formData.append('passportfile', selectedPassportPhotoFile);
    formData.append('projectType', localStorage.getItem("projectType"));
    formData.append('projectDescription', localStorage.getItem("projectDetails"))

    try {
      setLoading(true);
    const response = await axios.post(`${BASE_URL}/api/register`, formData, {
      withCredentials: true, // Include credentials in the request
    });

    if (response.data.Status === "Success") {
      setLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'You are successfully registered',
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        // Redirect to the new page after the SweetAlert is closed
        localStorage.clear();
        window.location.href = '/login'; // Replace '/new-page' with your desired URL
      });
    } else {
      setLoading(false);
      //alert(response.data.Message);
    }
      // const response = await fetch(`${BASE_URL}/api/register`, {
      //   method: 'POST',
      //   body: formData,
      //   credentials: "include",
      // });

      // if (!response.ok) {
      //   setLoading(false);
      //   const responseData = await response.json();
      //   alert(responseData.Message);
      // } else {
      //   setLoading(false);
      //   Swal.fire({
      //     icon: 'success',
      //     title: 'You are successfully registered',
      //     showConfirmButton: false,
      //     timer: 2000
      //   }).then(() => {
      //     // Redirect to the new page after the SweetAlert is closed
      //     localStorage.clear();
      //     window.location.href = '/login'; // Replace '/new-page' with your desired URL
      //   });

      // }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      alert('Something went wrong with registration. Try again.')
      // Handle network errors or exceptions
    }

  };

  // const phoneNumber = "254" + localStorage.getItem("phone");
  // const nationalId = localStorage.getItem("id");

  const phoneNumber = "+" + mpesaphone;
  const nationalId = mpesaACNo;

  const processPayment = async (e) =>{
    e.preventDefault();
    localStorage.setItem("id", mpesaACNo);
    localStorage.setItem("phone", mpesaphone);
    const data = { phoneNumber, nationalId };
    setLoading(true);
    await fetch(`${BASE_URL}/api/stk-push`, {
      method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'include',
        },
        body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.Status === "Success") {
        setLoading(false);
        console.log(data.Data.CheckoutRequestID);
        localStorage.setItem("checkoutId", data.Data.CheckoutRequestID);
                       //second alert
                       Swal.fire({
                        title: "MPESA prompt sent",
                        html: "A prompt has been sent to your phone, kindly check your phone to complete the payment. Click 'Confirm Payment' after Paying or if you did not receive the prompt click 'Pay Manually' to follow the instructions",
                        icon: "success",
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'Confirm Payment',
                        denyButtonText: `Pay Manually`,
                        allowOutsideClick: false,
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                          executeConfirmPayment();
                            //self.insertmpesatransaction(applicationno, billid);
                            //self.checkTransStatus(applicationno);
                        } else if (result.isDenied) {
                            Swal.fire({
                                title: 'To Pay manually follow the following instructions:',
                                width: 800,
                                html:
                                    '<div style="text-align: left;">' +
                                    '<b><p>1.</b> Go to your M-PESA menu</p>' +
                                    '<b><p>2.</b> Select Option Lipa na M-PESA</p>' +
                                    '<b><p>3.</b> Select Pay Bill option</p>' +
                                    '<b><p>4.</b> Select Enter business no. and enter <b>4091763</b> as the business number</p>' +
                                    '<b><p>5.</b> Select Account Number and enter Your <b>National ID</b> or <b>Passport Number</b></p>' +
                                    '<b><p>6.</b> Enter the exact amount <b>30,000</b></p>' +
                                    '<b><p>7.</b> Enter your PIN to complete payment</p>' +
                                    '<b><p>8.</b> Once you have paid click "Confirm Payment"</p>' +
                                    '</div>',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Confirm Payment',
                                    allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                  executeConfirmPayment();
                                    //self.checkTransStatus(applicationno);
                                }
                              })

                        }
                      })
        setShowButton(true);
      } else {
        //alert("Error");
        console.log(data.Message);
      }
    })
    .catch((error) => console.log(error));
  }

  const executeConfirmPayment = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/api/confirmPayment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'include',
		  mode: 'cors'
        },
        // Include the necessary data for confirmPayment function here
        body: JSON.stringify({
          CheckoutRequestID: localStorage.getItem("checkoutId"), // Adjust this based on your data structure
          // Add other required data here
        }),
      });
      if (response.ok) {
        setLoading(false);
        const result = await response.json();
        console.log(result)
        if(result.message.ResponseDescription === "The service request has been accepted successsfully"){
          setLoading(false);
          toast.success('Transaction Confirmed', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          localStorage.removeItem("checkoutId");

        }else{
          alert("We are unable to confirm your payment. Please Contact Us if you are having any challenges.");
          setLoading(false);
        }
      }
    } catch (error) {
      console.error('Error confirming payment:', error);
      setLoading(false);
    }
  };


  function validateForm() {
    var phoneNumber = document.getElementById("phone").value;

    if (phoneNumber.length < 9) {
        alert("Invalid phone number.");
    }
}

  return (
    <main>
      <Header />
      <section class="lg:mx-4 mt-20">
      <div class="bg-white dark:bg-gray-800 md:mx-2 mb-8">
          <div class="container p-4 mx-auto">

          <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
        <h2 className="mb-4 text-[30px] font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide">
          Create an Account
        </h2>
        <p className="mb-5 text-center font-bold text-[#707070] sm:text-[18px] lg:text-xl dark:text-gray-400 font-lufga">
        Become a home owner today by Enrolling as a member.
        </p>
      </div>


        </div>
      </div>



        <div class="mx-auto my-8 max-w-screen-xl overflow-hidden md:pl-8 bg-[#FFFCF2]">
          {/* Title Section */}
          <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
            <h2 className="mb-4 text-[30px] font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide">
              How you can Register?
            </h2>
            <p className="mb-5 text-center font-bold text-[#707070] sm:text-[18px] lg:text-[16px] dark:text-gray-400 font-lufga">
              There are 3 main ways a new member will be able to express their
              interest to <br /> join Promitto Homes
            </p>
          </div>
          <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 mx-8 mb-8">
            {/* <!-- Pricing Card --> */}
            <div
              class="flex flex-col max-w-lg p-6 mx-auto w-90 text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              data-aos="fade-right"
            >
              <h3 class="mt-6 text-6xl text-[#F2B807] font-lufgaBold">01</h3>
              <p class="text-gray-600 sm:text-sm dark:text-gray-500 font-lufga mb-8">
                Call or Whatsapp <strong>+254 729 506 506 </strong>.
              </p>
            </div>
            {/* <!-- Pricing Card --> */}
            <div
              class="flex flex-col max-w-lg p-6 mx-auto w-90 text-left text-gray-900 bg-[#F2B807] border rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              data-aos="fade-up"
            >
              <h3 class="mt-6 text-6xl text-[#010440] font-lufgaBold">02</h3>
              <p class="text-gray-600 sm:text-sm dark:text-gray-500 font-lufga mb-8">
                Log in to Promitto Website and go to new member page and follow
                the instructions.
              </p>
            </div>
            {/* <!-- Pricing Card --> */}
            <div
              class="flex flex-col max-w-lg p-6 mx-auto w-90 text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              data-aos="fade-left"
            >
              <h3 class="mt-6 text-6xl text-[#F2B807] font-lufgaBold">03</h3>
              <p class="text-gray-600 sm:text-sm dark:text-gray-500 font-lufga mb-8">
                Visit Promitto Office and follow the guidance from customer
                Service Attendant.
              </p>
            </div>
          </div>
        </div>
        {/* Tabs Section */}
        <div class="bg-white rounded-lg border shadow-lg shadow-[#efd27b] dark:bg-gray-800 md:mx-2 mb-8">
        <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
            <h2
              className="mb-4 text-[30px] font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide"
              data-aos="fade-down"
            >
              Register Now?
            </h2>
            <p
              className="mb-5 text-center font-bold text-[#707070] sm:text-[18px] lg:text-xl dark:text-gray-400 font-lufga"
              data-aos="fade-up"
            >
              Membership Registration Form.
            </p>
          </div>
          <div className="mb-3 mx-16">
            <TETabs justify>
              <TETabsItem
                onClick={() => handleJustifyClick("tab1")}
                active={justifyActive === "tab1"}
                color="warning"
              >
                <h3 className="font-lufgaBold text-sm normal-case">
                  Application Instructions.
                </h3>
              </TETabsItem>
              <TETabsItem
                onClick={() => handleJustifyClick("tab2")}
                active={justifyActive === "tab2"}
                color="warning"
              >
                <h3 className="font-lufgaBold text-sm normal-case">
                  Project Details.
                </h3>
              </TETabsItem>
              <TETabsItem
                onClick={() => handleJustifyClick("tab3")}
                active={justifyActive === "tab3"}
                color="warning"
              >
                <h3 className="font-lufgaBold text-sm normal-case">
                  Personal Information.
                </h3>
              </TETabsItem>
              <TETabsItem
                onClick={() => handleJustifyClick("tab4")}
                active={justifyActive === "tab4"}
                color="warning"
              >
                <h3 className="font-lufgaBold text-sm normal-case">
                  Next of Kin Details.
                </h3>
              </TETabsItem>
              <TETabsItem
                onClick={() => handleJustifyClick("tab5")}
                active={justifyActive === "tab5"}
                color="warning"
              >
                <h3 className="font-lufgaBold text-sm normal-case">
                  Payment Details.
                </h3>
              </TETabsItem>
              <TETabsItem
                onClick={() => handleJustifyClick("tab6")}
                active={justifyActive === "tab6"}
                color="warning"
                // disabled
              >
                <h3 className="font-lufgaBold text-sm normal-case">Review.</h3>
              </TETabsItem>
            </TETabs>

            <TETabsContent>
              <TETabsPane show={justifyActive === "tab1"}>
              <div>
              <p class=" text-[#F2B807] sm:text-lg mb-4 mt-4 dark:text-gray-600 font-lufgaBold mr-8">
                          Please confirm you have the following requirements before proceeding with the application
                          </p>
                          <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
                          <li>
                              <ul class="pl-5 mt-2 space-y-1 list-disc list-outside">
                                  <li className="text-sm font-lufga text-gray-700 font-semibold">A softcopy of your <strong>National Identity Card</strong> or <strong>Passport Document</strong> (Preferrably in image format as seperate documents) </li>
                                  <li className="text-sm font-lufga text-gray-700 font-semibold"><strong>KSH 30,000 in your MPESA for your account application fees to cater for site visit facilitation and bill of quantities preparation </strong></li>
                                  <li className="text-sm font-lufga text-gray-700 font-semibold">A Colored Passport Photo</li>
                                  <li className="text-sm font-lufga text-gray-700 font-semibold">A Copy of your Title deed</li>
                                  <li className="text-sm font-lufga text-gray-700 font-semibold">Next of Kin details (Full Name, ID Number, Phone Number, Date of Birth )</li>
                                </ul>
                          </li>
                          </ol>
              </div>
<div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mt-6">
                    <a onClick={() => handleJustifyClick("tab2")} active={justifyActive === "tab2"} class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F2B807] justify-center w-full px-5 py-2 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  dark:text-white">
                       Next <BsArrowRightCircle className='text-black font-bold ml-2' />
                   </a>

                  </div>
              </TETabsPane>
              <TETabsPane show={justifyActive === "tab2"}>
              <div>
              <p class=" text-[#F2B807] sm:text-lg mb-4 mt-4 dark:text-gray-600 font-lufgaBold mr-8">
                What do you have in mind? Please provide a detailed description of the project.
                          </p>

              </div>
              <div className="mt-8 lg:w-1/2 lg:mx-6">
                <div
                    className="w-full px-8 py-10 mx-auto overflow-hidden bg-white rounded-lg shadow-2xl dark:bg-gray-900 lg:max-w-xl shadow-gray-300/50 dark:shadow-black/50">
                    <h1 className="text-lg font-lufgaBold text-gray-700">Select Project type</h1>

                    <form className="mt-6">
                        <div className="flex-1">
                            <label className="block mb-2 font-lufga text-sm text-gray-600 dark:text-gray-200">Project Type</label>
                            <select
                            id="selectedOption"
                            value={selectedProjectType} // Set the value to the selected manager's ID
                            onChange={handleProjectTypeChange}
                            className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40"
                            name=""
                            required
                          >
                            <option className="hover:bg-[#F2B807]" value="" disabled>
                              --Select a project type--
                            </option>
                            {listingTypes.map((listing) => (
                              <option key={listing.ID} value={listing.type}>
                                {listing.type}
                              </option>

                            ))}
                            <option value="Other">Other</option>
                          </select>
                        </div>

                        <div className="w-full mt-6">
                            <label className="block font-lufga mb-2 text-sm text-gray-600 dark:text-gray-200">Project Description</label>
                            <textarea
                            value={projectDetails}
                            onChange={handleProjectDetailsChange}
                             className="block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-48 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40" placeholder=""></textarea>
                        </div>
                    <div className="space-y-8 sm:flex sm:space-y-0 sm:space-x-8 space-x-8 mt-6">
                    <a
                      onClick={() => handleJustifyClick("tab1")}
                      active={justifyActive === "tab1"}
                      class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F2B807] justify-center w-full px-5 py-2 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  dark:text-white"
                    >
                      <BsArrowLeftCircle className="text-black font-bold mr-2" />
                      Previous
                    </a>
                    <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mt-6">
                   <button onClick={handleSubmitProjectDetails} className="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F2B807] justify-center w-full px-5 py-2 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  dark:text-white">Next <BsArrowRightCircle className='text-black font-bold ml-2' /></button>
                  </div>
                  </div>

                    </form>
                </div>
            </div>
              </TETabsPane>
              <TETabsPane show={justifyActive === "tab3"}>
                {/* Tab 1 content */}
                <form className="mt-10" onSubmit={handleSubmit}>
                  <div class="grid gap-6 mb-6 md:grid-cols-3">
                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                          First Name. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setfirstName(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Middle Name. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="middleName"
                        value={middleName}
                        onChange={(e) => setmiddleName(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Last Name. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setlastName(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>


                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Gender. <span className="text-[#F2B807]">*</span>
                        </label>

                        <select
                        id="default"
                        value={gender}
                        onChange={(e) => setgender(e.target.value)}
                        name="gender"
                        class="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-[#F2B807] focus:border-[#F2B807] block w-full px-4 py-2.5 mt-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-[#F2B807] dark:focus:border-[#F2B807]"
                      >
                        <option disabled value="">
                          Select gender
                        </option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </select>
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Date of Birth. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="date"
                        value={dob}
                        onChange={(e) => setdob(e.target.value)}
                        id="username" type="date"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        National ID/ Passport No. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="id"
                        value={id}
                        onChange={(e) => setid(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        KRA PIN No. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="firstName"
                        value={krapin}
                        onChange={(e) => setkrapin(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Address. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="firstName"
                        value={address}
                        onChange={(e) => setaddress(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Postal Code. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="firstName"
                        value={postalcode}
                        onChange={(e) => setpostalcode(e.target.value)}
                        id="username" type="number"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                    </div>

                    <div>
                    <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Country. <span className="text-[#F2B807]">*</span>
                        </label>
                      {/* <input type="text" value={country} onChange={e => setcountry(e.target.value)} id="first_name" class="bg-white border-[2px] border-[#707070] text-gray-900 text-sm rounded-lg focus:ring-[#F2B807] focus:border-[#F2B807] block w-full py-3.5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Country" required /> */}
                      <select
                        value={country}
                        onChange={(e) => setcountry(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-[#F2B807] focus:border-[#F2B807] block w-full px-4 py-2.5 mt-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-[#F2B807] dark:focus:border-[#F2B807]"
                      >
                        <option disabled value="">
                          Select Country
                        </option>
                        {countries.map((option) => (
                          <option key={option.name} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        City. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="lastName"
                        value={city}
                        onChange={(e) => setcity(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                    </div>

                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        County/ State. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="lastName"
                        value={county}
                        onChange={(e) => setcounty(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                    </div>


                    <div>
                    <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Phone Number. <span className="text-[#F2B807]">*</span>
                        </label>
                       <div class="relative">
                        <span class="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-600">
                            +
                        </span>
                            <input
                            placeholder="e.g 2547xxxxxxx"
                            value={phone}
                            onChange={(e) => setphone(e.target.value)}
                                class="w-full pr-3 py-2 mt-0.5 pl-12 border rounded-md border-gray-300 focus:outline-none focus:ring-[#F2B807] focus:border-[#F2B807]"
                                type="text"
                                id="phone"
                                name="phone"
                            />
                        </div>
                        </div>

                        <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Email. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="email"
                        value={email}
                            onChange={(e) => setemail(e.target.value)}
                        id="email" type="email"
                        class="block w-full px-4 py-2 mt- text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                    </div>

                  </div>
                  <div className="space-y-8 sm:flex sm:space-y-0 sm:space-x-8 space-x-8 mt-6">
                    <a
                      onClick={() => handleJustifyClick("tab2")}
                      active={justifyActive === "tab2"}
                      class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F2B807] justify-center w-full px-5 py-2 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  dark:text-white"
                    >
                      <BsArrowLeftCircle className="text-black font-bold mr-2" />
                      Previous
                    </a>
                    <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mt-6">
                   <button type="submit" className="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F2B807] justify-center w-full px-5 py-2 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  dark:text-white">Next <BsArrowRightCircle className='text-black font-bold ml-2' /></button>
                  </div>
                  </div>
                </form>
              </TETabsPane>
              <TETabsPane show={justifyActive === "tab4"}>
                {/* Tab 2 content */}
                <form className="mt-10" onSubmit={handleSubmitKinDetails}>
                  <div class="grid gap-6 mb-6 md:grid-cols-3">

                  <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                          First Name. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="firstName"
                        value={kinfirstName}
                        onChange={(e) => setkinfirstName(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                  </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Middle Name. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="middleName"
                        value={kinmiddleName}
                        onChange={(e) => setkinmiddleName(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Last Name. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="lastName"
                        value={kinlastName}
                        onChange={(e) => setkinlastName(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                    <div>
                    <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Relationship. <span className="text-[#F2B807]">*</span>
                        </label>
                      <select
                        id="default"
                        value={kinrelationship}
                        onChange={(e) => setkinrelationship(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-[#F2B807] focus:border-[#F2B807] block w-full px-4 py-2.5 mt-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-[#F2B807] dark:focus:border-[#F2B807]"
                      >
                        <option disabled value="">
                          Select relationship
                        </option>
                        <option value="SPOUSE">Spouse</option>
                        <option value="FATHER">Father</option>
                        <option value="MOTHER">Mother</option>
                        <option value="SISTER">Sister</option>
                        <option value="BROTHER">Brother</option>
                        <option value="OTHER">Other</option>
                      </select>
                    </div>
                    <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Phone Number. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="lastName"
                        value={kinphone}
                        onChange={(e) => setkinphone(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>

                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        National ID/ Passport No. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="lastName"
                        value={kinid}
                        onChange={(e) => setkinid(e.target.value)}
                        id="username" type="text"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                      <div>
                        <label class="text-gray-700 dark:text-gray-200 font-lufga" for="username">
                        Date of Birth. <span className="text-[#F2B807]">*</span>
                        </label>
                        <input
                        name="lastName"
                        value={kindob}
                        onChange={(e) => setkindob(e.target.value)}
                        id="username" type="date"
                        class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-[#F2B807] focus:ring-[#F2B807] focus:ring-opacity-40 dark:focus:border-[#F2B807] focus:outline-none focus:ring"
                        required/>
                      </div>
                  </div>
                  <div className="space-y-8 sm:flex sm:space-y-0 sm:space-x-8 space-x-8">
                    <a
                      onClick={() => handleJustifyClick("tab3")}
                      active={justifyActive === "tab4"}
                      class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F2B807] justify-center w-full px-5 py-2 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  dark:text-white"
                    >
                      <BsArrowLeftCircle className="text-black font-bold mr-2" />
                      Previous
                    </a>

                    <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                   <button type="submit" className="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F2B807] justify-center w-full px-5 py-2 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  dark:text-white">Next <BsArrowRightCircle className='text-black font-bold ml-2' /></button>
                  </div>
                  </div>
                </form>
              </TETabsPane>
              <TETabsPane show={justifyActive === "tab5"}>
                {/* Tab 3 content */}
                <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
                  <h2 className="mb-4 text-lg font-bold text-black dark:text-white font-lufga tracking-wide">
                    Payment Options
                  </h2>
                  <p className="mb-5 text-center text-black sm:text-[14px] lg:text-sm dark:text-gray-400 font-lufga tracking-wide">
                  Kindly proceed to pay KES 30,000 for your account application fees to cater for site visit facilitation and bill of quantities preparation.
                  </p>
                  <div class="grid grid:cols-1 md:grid-cols-2 gap-2 md:mx-36">
                      <div class="h-90 min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2">
                      <a
                        onClick={() => displayMpesaCard()}
                        // active={justifyActive === "tab2"}
                        class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F1FCFB] justify-center w-full px-5 py-1.5 text-sm font-bold text-center text-black border border-[#53C064] rounded-[50px] sm:w-auto  dark:text-white"
                      >
                        <img
                          src={MpesaSvg}
                          alt="Logo"
                          className="h-9 mx-10 my-0.5"
                        />
                      </a>
                  </div>


                    <div class="h-90 min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2">
                      <a
                        onClick={() => displayVisaCard()}
                        // active={justifyActive === "tab2"}
                        class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F1F2FC] justify-center w-full px-5 py-1.5 text-sm font-bold text-center text-black border border-[#F2B807] rounded-[50px] sm:w-auto  dark:text-white"
                      >
                        <img
                          src={VisaSvg}
                          alt="Logo"
                          className="h-5 mx-10 my-2.5"
                        />
                      </a>
                    </div>

                  </div>


                  {/* <div class="mx-auto grid max-w-screen-lg grid-cols-1 gap-5 p-5 sm:grid-cols-2 md:grid-cols-3 lg:gap-10">
                    <article class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2">
                      <a
                        onClick={() => handleJustifyClick("tab3")}
                        active={justifyActive === "tab2"}
                        class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F1FCFB] justify-center w-full px-5 py-1.5 text-sm font-bold text-center text-black border border-[#53C064] rounded-[50px] sm:w-auto  dark:text-white"
                      >
                        <img
                          src={MpesaSvg}
                          alt="Logo"
                          className="h-8 mx-10 my-0.5"
                        />
                      </a>
                    </article>

                    <article class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2">
                      <a
                        onClick={() => handleJustifyClick("tab3")}
                        active={justifyActive === "tab2"}
                        class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F1F2FC] justify-center w-full px-5 py-1.5 text-sm font-bold text-center text-black border border-[#1333CA] rounded-[50px] sm:w-auto  dark:text-white"
                      >
                        <img
                          src={VisaSvg}
                          alt="Logo"
                          className="h-4 mx-10 my-2.5"
                        />
                      </a>
                    </article>

                    <article class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2">
                      <a
                        onClick={() => handleJustifyClick("tab3")}
                        active={justifyActive === "tab2"}
                        class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#FCF1F3] justify-center w-full px-5 py-1.5 text-sm font-bold text-center text-black border border-[#EE4048] rounded-[50px] sm:w-auto  dark:text-white"
                      >
                        <p className="font-lufga mx-6 my-[8px]">Coming Soon</p>
                      </a>
                    </article>
                  </div> */}

                  <div class="flex flex-col items-center justify-center px-4">
                    {showmpesaCard && (
                    <div class="m-10 h-fit w-full items-left rounded-lg border-[2px] border-[#BDE8C8] bg-[#FBFFFC] px-10 py-5 text-gray-700">
                      <img
                          src={MpesaSvg}
                          alt="Logo"
                          className="h-12 my-0.5"
                        />
                      <p class="mt-2 text-[16px] text-[#212121] text-left font-lufgaBold">
                        Payment Instructions
                      </p>
                      <div className="flex items-center">
                        <FiCheck className="text-[#3AB54B] font-bold mr-2" />
                        <p class="text-[16px] text-[#212121] text-left font-lufga">
                          You will receive an MPESA prompt on your phone to complete payment of KES
                          30,000/= to Promitto Ltd.
                        </p>
                      </div>


                      <div class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2"></div>
                       <form>
                       <div class="flex justify-start space-x-2 my-6">
                       <a
                          data-te-toggle="modal"
                          data-te-target="#exampleModalCenter"
                          class="cursor-pointer w-full rounded-lg bg-[#3AB54B] px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-[#53C064] ring-offset-2 transition hover:bg-[#53C064] hover:cursor-pointer focus:ring-2 md:w-1/2"
                        >
                          Make Payment
                        </a>

                      </div>


                      <div
              data-te-modal-init
              class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
              id="exampleModalCenter"
              tabindex="-1"
              aria-labelledby="exampleModalCenterTitle"
              aria-modal="true"
              role="dialog">
              <div
                data-te-modal-dialog-ref
                class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
                <div
                  class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                  <div
                    class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

                    <h5
                      class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                      id="exampleModalCenterTitle">
                      Payment Confirmation Details
                    </h5>
                  </div>

      <div class="relative p-4">
      <div>
          <label htmlFor="email" className="block mb-2 text-sm text-gray-500 font-lufgaBold dark:text-gray-200">Confirm that you want to pay using this phonenumber or edit it</label>
          <div class="relative">
                        <span class="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-600">
                            +
                        </span>
                            <input
                            value={mpesaphone}
                            onChange={handlePhoneChange}
                                class="w-full pr-3 py-2 mt-0.5 pl-12 border rounded-md border-gray-300 focus:outline-none focus:ring-[#F2B807] focus:border-[#F2B807]"
                                type="text"
                                id="mpesaPhone"
                                name="mpesaPhone"
                                maxlength="9"
                                placeholder="e.g 2547xxxxxxx"
                            />
                        </div>
          </div>
      <div>
          <label htmlFor="email" className="block mb-2 text-sm text-gray-500 font-lufgaBold dark:text-gray-200">National ID/ Passport NO (Will act as the Account Number)</label>
          <input type="text"
            value={mpesaACNo}
            onChange={handleIDChange}
          placeholder="Enter a Valid National ID Number/ Passport NO." className="block w-full px-4 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border text-sm border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" required/>
      </div>
      </div>



      <div
        class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

{loading && (
    <div className="flex items-center">
      <div className="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
      <div className="ml-2 mr-4">Please Wait....</div>
    </div>
  )}
        <button
          type="button"
          class="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
          data-te-modal-dismiss
          data-te-ripple-init
          data-te-ripple-color="light">
          Close
        </button>
        <button
        onClick={processPayment}
          class="ml-1 inline-block rounded bg-[#3AB54B] px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[#3AB54B] hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-[#3AB54B] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-[#3AB54B] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-ripple-init
          data-te-ripple-color="light">
          Continue
        </button>
      </div>
    </div>
  </div>
                      </div>
                       </form>
                       {/* {loading &&
                        <div class="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                        <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                          >Loading...</span>
                          </div>
                       } */}



                    </div>
                    )}
                    {/* Visa Card */}
                    {showvisaCard && (
                    <div class="m-10 h-fit w-full items-left rounded-lg border-[2px] border-[#F2B807] bg-[#F1F2FC] px-10 py-5 text-gray-700">
                       <img
                          src={VisaSvg}
                          alt="Logo"
                          className="h-10 my-2.5"
                        />
                      <p class="mt-2 text-[16px] text-[#212121] text-left font-lufgaBold">
                        Payment Instructions
                      </p>
                      <div className="flex items-center">
                        <FiCheck className="text-black font-bold mr-2" />
                        <p class="text-[16px] text-[#212121] text-left font-lufga">
                        For security purposes, we will send you an invoice on your registered email to make the payment to
                        Promitto Ltd.
                        </p>
                      </div>
                      <div className="flex items-center">
                        <FiCheck className="text-black font-bold mr-2" />
                        <p class="text-[16px] text-[#212121] text-left font-lufga">
                        Select your preferred currency
                        </p>
                      </div>
                      <form>
<div>
                      <select
                        id="default"
                        value={currency}
                        onChange={handleCurrencyChange}
                        class="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-[#F2B807] focus:border-[#F2B807] block w-full px-4 py-3 mt-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-[#F2B807] dark:focus:border-[#F2B807]"
                      >
                        <option className="text-lg font-lufga" disabled value="">
                          Select currency
                        </option>
                        <option className="text-lg font-lufga" value="US Dollars">US Dollars</option>
                        <option className="text-lg font-lufga" value="Kenyan Shillings">Kenyan Shillings</option>
                      </select>
                    </div>



                      <div className="mt-6">
                            <input
                            required
                            value={amountVisa}
                            onChange={handleamountVisaChange}
                             type="number" min={1} placeholder="Enter amount e.g 30000" className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>

                      <div class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2"></div>

                       <div class="flex justify-start space-x-2 my-6">
                       <button onClick={submitCardPayment}
                          class="cursor-pointer w-full rounded-lg bg-[#181a46] px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-[#F2B807] ring-offset-2 transition hover:bg-[#F2B807] hover:cursor-pointer focus:ring-2 md:w-1/2"
                        >
                          Send Me an Invoice
                        </button>

                      </div>

                       </form>



                    </div>
                    )}


                    {
                      showButton && (
                        <button
                    onClick={() => handleJustifyClick("tab6")}
                      active={justifyActive === "tab6"}
                      class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F2B807] justify-center w-full px-5 py-2 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  dark:text-white"
                    >
                      Next{" "}
                      <BsArrowRightCircle className="text-black font-bold ml-2" />
                    </button>
                      )
                    }

                  </div>
                </div>
              </TETabsPane>
              <TETabsPane show={justifyActive === "tab6"}>
                {/* Tab 4 content */}
                <div class="lg:m-10">
                  {localStorage ? (
                    <>
                      <form
                        onSubmit={postUser}
                        encType="multipart/form-data"
                        class="relative border border-gray-100 space-y-3 max-w-screen-md mx-auto rounded-md bg-white p-6 shadow-xl lg:p-10"
                      >
                        <h1 class="mb-6 text-xl font-lufgaBold lg:text-2xl text-center">
                          Confirm your details before proceeding
                        </h1>
                        <h1 class="mb-6 text-lg font-lufgaBold lg:text-lg text-[#F2B807]">
                          Project Details
                        </h1>

                        <div className="flex-1 mt-6">
                            <label className="block font-lufga mb-2 text-sm text-gray-600 dark:text-gray-200 ">Project Type</label>
                            <input
                            name="projectType"
                            value={localStorage.getItem("projectType")}
                            readOnly
                            required
                            type="text" placeholder="" className="block w-1/2 px-3 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-gray-100 border-[#F2B807] rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>

                        <div className="w-full mt-6">
                            <label className="block font-lufga mb-2 text-sm text-gray-600 dark:text-gray-200">Project Details</label>
                            <textarea
                            name="projectDetails"
                            value={localStorage.getItem("projectDetails")}
                            readOnly
                            required
                            className="block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-gray-100 border-[#F2B807]  rounded-md md:h-48 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-[#F2B807] dark:focus:border-[#F2B807] focus:ring-[#F2B807] focus:outline-none focus:ring focus:ring-opacity-40" placeholder="Message"></textarea>
                        </div>

                        <h1 class="mb-6 text-lg font-lufgaBold lg:text-lg text-[#F2B807]">
                          Personal Information:
                        </h1>
                        <div class="grid gap-3 md:grid-cols-3">
                          <div>
                            <label class="font-lufga"> First Name </label>
                            <input
                              type="text"
                              placeholder=""
                              name="firstName"
                              value={localStorage.getItem("firstName")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> Middle Name </label>
                            <input
                              type="text"
                              placeholder=""
                              name="middleName"
                              value={localStorage.getItem("middleName")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> Last Name </label>
                            <input
                              type="text"
                              placeholder=""
                              name="lasttName"
                              value={localStorage.getItem("lastName")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                        </div>
                        <div class="grid gap-3 md:grid-cols-3">
                          <div>
                            <label class="font-lufga"> Gender </label>
                            <input
                              type="text"
                              placeholder=""
                              name="gender"
                              value={localStorage.getItem("gender")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> Date of Birth </label>
                            <input
                              type="text"
                              placeholder=""
                              name="dob"
                              value={localStorage.getItem("dob")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga">
                              {" "}
                              Nationa ID/ Passport No.{" "}
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              name="id"
                              value={localStorage.getItem("id")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                        </div>
                        <div class="grid gap-3 md:grid-cols-3">
                          <div>
                            <label class="font-lufga"> KRA PIN No. </label>
                            <input
                              type="text"
                              placeholder=""
                              name="krapin"
                              value={localStorage.getItem("krapin")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> Address </label>
                            <input
                              type="text"
                              placeholder=""
                              name="address"
                              value={localStorage.getItem("address")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> Postal Code </label>
                            <input
                              type="text"
                              placeholder=""
                              name="postalcode"
                              value={localStorage.getItem("postalcode")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                        </div>

                        <div class="grid gap-3 md:grid-cols-3">
                          <div>
                            <label class="font-lufga"> Country </label>
                            <input
                              type="text"
                              placeholder=""
                              name="country"
                              value={localStorage.getItem("country")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> City </label>
                            <input
                              type="text"
                              placeholder=""
                              name="city"
                              value={localStorage.getItem("city")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> County/ State </label>
                            <input
                              type="text"
                              placeholder=""
                              name="county"
                              value={localStorage.getItem("county")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                        </div>
                        <div class="grid gap-3 md:grid-cols-3">
                          <div>
                            <label class="font-lufga"> Phone Number </label>
                            <input
                              type="text"
                              placeholder=""
                              name="phone"
                              value={localStorage.getItem("phone")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> Email </label>
                            <input
                              type="text"
                              placeholder=""
                              name="email"
                              value={localStorage.getItem("email")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                        </div>
                        <h1 class="mb-6 text-lg font-lufgaBold lg:text-lg text-[#F2B807]">
                          Next of Kin Details:
                        </h1>
                        <div class="grid gap-3 md:grid-cols-3">
                          <div>
                            <label class="font-lufga"> First Name </label>
                            <input
                              type="text"
                              placeholder=""
                              name="kinfirstName"
                              value={localStorage.getItem("kinfirstName")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> Middle Name </label>
                            <input
                              type="text"
                              placeholder=""
                              name="kinmiddleName"
                              value={localStorage.getItem("kinmiddleName")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> Last Name </label>
                            <input
                              type="text"
                              placeholder=""
                              name="kinlastName"
                              value={localStorage.getItem("kinlastName")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                        </div>
                        <div class="grid gap-3 md:grid-cols-3">
                          <div>
                            <label class="font-lufga"> Relationship </label>
                            <input
                              type="text"
                              placeholder=""
                              name="kinrelationship"
                              value={localStorage.getItem("kinrelationship")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga"> Phone Number </label>
                            <input
                              type="text"
                              placeholder=""
                              name="kinphone"
                              value={localStorage.getItem("kinphone")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                          <div>
                            <label class="font-lufga">
                              {" "}
                              National ID. /Passport No.{" "}
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              name="kinid"
                              value={localStorage.getItem("kinid")}
                              class="font-lufgaBold text-sm mt-2 h-10 w-full rounded-md bg-gray-100 px-3 border-[#F2B807] focus:ring-[#F2B807] focus:border-[#F2B807]"
                              readOnly
                            />
                          </div>
                        </div>


                        <div className="space-y-8 sm:flex sm:space-y-0 sm:space-x-8 space-x-8">
                          <a
                            onClick={() => handleJustifyClick("tab3")}
                            active={justifyActive === "tab3"}
                            class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F2B807] justify-center w-full px-5 py-2 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  dark:text-white"
                          >
                            <BsArrowLeftCircle className="text-black font-bold mr-2" />
                            Back to Edit
                          </a>
                          <button
                            data-te-toggle="modal"
                            data-te-target="#exampleModalCenter1"
                            data-te-ripple-init
                            class="cursor-pointer inline-flex items-center font-lufgaBold bg-[#F2B807] justify-center w-full px-5 py-2 text-sm font-bold text-center text-black border border-[#F2B807] rounded-lg sm:w-auto  dark:text-white"
                          >
                            Finish Registration{" "}
                            <BsArrowRightCircle className="text-black font-bold ml-2" />
                          </button>
                        </div>
                        <div
                          data-te-modal-init
                          class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                          id="exampleModalCenter1"
                          tabindex="-1"
                          aria-labelledby="exampleModalCenterTitle"
                          aria-modal="true"
                          role="dialog"
                        >
                          <div
                            data-te-modal-dialog-ref
                            class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
                          >
                            <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                              <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                {/* <!--Modal title--> */}
                                <h5
                                  class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                  id="exampleModalScrollableLabel"
                                >
                                  Please Upload these files to finish your
                                  Application
                                </h5>
                                {/* <!--Close button--> */}
                                <button
                                  type="button"
                                  class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                  data-te-modal-dismiss
                                  aria-label="Close"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="h-6 w-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </div>
                              {/* <!--Modal body--> */}
                               {/* <!--Modal body--> */}
                              <div className='mx-8'>
                              <p class="mb-2 text-sm font-medium">Passport Photo Image</p>
                              <input type="file" name="passportfile" className='' onChange={handlePassportPhotoFileChange} />
                              <p class="mb-2 text-sm font-medium">National ID/ Passport</p>
                              <input type="file" name="idfile" className='' onChange={handleIDFileChange} />
                              <p class="mb-2 mt-4 text-sm font-medium">KRA PIN Certificate</p>
                              <input type="file" name="krafile" onChange={handleKraFileChange} />
                              <p class="mb-2 mt-4 text-sm font-medium">Title Deed Copy</p>
                              <input type="file" name="titlefile" onChange={handleTitleDeedFileChange} />
                              </div>


                              {/* <!--Modal footer--> */}
                              <div class="flex mr-12 flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                              {loading && (
                                  <div className="flex items-center">
                                    <div className="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                        Loading...
                                      </span>
                                    </div>
                                    <div className="ml-2 mr-4">Please Wait....</div>
                                  </div>
                                )}

                                <button
                                  type="button"
                                  class="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                  data-te-modal-dismiss
                                  data-te-ripple-init
                                  data-te-ripple-color="light"
                                >
                                  Close
                                </button>
                                <button
                                type="submit"
                                //onClick={postUser}
                                  class="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                  data-te-ripple-init
                                  data-te-ripple-color="light"
                                >
                                  Submit
                                </button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  ) : (
                    <p>No stored form data found.</p>
                  )}
                </div>
              </TETabsPane>
            </TETabsContent>
          </div>
         </div>
        {/* Loan Calculator Section */}
        <LoanCalculator />
      </section>
      <Footer />
    </main>
  );
};

export default Register;

import React, { useEffect } from "react";
import AOS from "aos";
const CoreValues = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <section class="bg-white dark:bg-gray-900">
      <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
        <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
          <h2
            className="mb-4 text-4xl font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide"
            data-aos="fade-down"
          >
            Our Core Values
          </h2>
          <p
            className="mb-5 text-center text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga"
            data-aos="fade-up"
          >
            At Promitto LTD, our core values serve as the guiding principles
            that shape every aspect of our business. These core values are not
            just words; they are the essence of Promitto LTD, shaping our
            culture and ensuring that we consistently deliver the highest
            standard of service and results.
          </p>
        </div>

        <div
          class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0"
          data-aos="zoom-in"
        >
          {/* <!-- Pricing Card --> */}
          <div class="flex flex-col max-w-lg p-2 mx-auto text-left text-gray-900 bg-whitexl:p-2 dark:bg-gray-800 dark:text-white">
            <div class="flex items-left mt-6 -mx-2">
              <h3 className="text-[#F2B807] font-lufgaBold text-4xl">01</h3>
              <div class="mx-2">
                <h4 class="text-gray-800 dark:text-white font-lufgaBold text-xl">
                  Trust.
                </h4>
                <span class="text-sm text-gray-600 dark:text-gray-400 font-lufga">
                  Trust is a fundamental core value at Promitto LTD. We
                  understand that trust is the cornerstone of any successful
                  relationship, and we work diligently to earn and maintain the
                  trust of our clients.
                </span>
              </div>
            </div>
          </div>

          {/* <!-- Pricing Card --> */}
          <div class="flex flex-col max-w-lg p-2 mx-auto text-left text-gray-900 bg-whitexl:p-2 dark:bg-gray-800 dark:text-white">
            <div class="flex items-left mt-6 -mx-2">
              <h3 className="text-[#F2B807] font-lufgaBold text-4xl">02</h3>
              <div class="mx-2">
                <h4 class="text-gray-800 dark:text-white font-lufgaBold text-xl">
                  Customer Satisfaction..
                </h4>
                <span class="text-sm text-gray-600 dark:text-gray-400 font-lufga">
                  We are committed to providing exceptional customer service,
                  treating each client with respect, and ensuring their
                  satisfaction throughout their real estate journey.
                </span>
              </div>
            </div>
          </div>
          {/* <!-- Pricing Card --> */}
          <div class="flex flex-col max-w-lg p-2 mx-auto text-left text-gray-900 bg-whitexl:p-2 dark:bg-gray-800 dark:text-white">
            <div class="flex items-left mt-6 -mx-2">
              <h3 className="text-[#F2B807] font-lufgaBold text-4xl">03</h3>
              <div class="mx-2">
                <h4 class="text-gray-800 dark:text-white font-lufgaBold text-xl">
                  Innovation.
                </h4>
                <span class="text-sm text-gray-600 dark:text-gray-400 font-lufga">
                  Our dedication to excellence drives us to constantly improve,
                  staying at the forefront of industry trends and delivering
                  outstanding quality in every interaction..
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0"
          data-aos="zoom-in"
        >
          {/* <!-- Pricing Card --> */}
          <div class="flex flex-col max-w-lg p-2 mx-auto text-left text-gray-900 bg-whitexl:p-2 dark:bg-gray-800 dark:text-white">
            <div class="flex items-left mt-6 -mx-2">
              <h3 className="text-[#F2B807] font-lufgaBold text-4xl">04</h3>
              <div class="mx-2">
                <h4 class="text-gray-800 dark:text-white font-lufgaBold text-xl">
                  Inclusion.
                </h4>
                <span class="text-sm text-gray-600 dark:text-gray-400 font-lufga">
                  We embrace collaboration, working closely with our clients,
                  partners, and team members to foster strong relationships and
                  achieve mutual success.
                </span>
              </div>
            </div>
          </div>

          {/* <!-- Pricing Card --> */}
          <div class="flex flex-col max-w-lg p-2 mx-auto text-left text-gray-900 bg-whitexl:p-2 dark:bg-gray-800 dark:text-white">
            <div class="flex items-left mt-6 -mx-2">
              <h3 className="text-[#F2B807] font-lufgaBold text-4xl">05</h3>
              <div class="mx-2">
                <h4 class="text-gray-800 dark:text-white font-lufgaBold text-xl">
                  Impact.
                </h4>
                <span class="text-sm text-gray-600 dark:text-gray-400 font-lufga">
                  We take pride in our community, actively engaging and giving
                  back, as we believe in the power of real estate to positively
                  impact the lives of individuals and contribute to the growth
                  and development of our society.
                </span>
              </div>
            </div>
          </div>
          {/* <!-- Pricing Card --> */}
          <div class="flex flex-col max-w-lg p-2 mx-auto text-left text-gray-900 bg-whitexl:p-2 dark:bg-gray-800 dark:text-white">
            <div class="flex items-left mt-6 -mx-2">
              <h3 className="text-[#F2B807] font-lufgaBold text-4xl">06</h3>
              <div class="mx-2">
                <h4 class="text-gray-800 dark:text-white font-lufgaBold text-xl">
                  Integrity.
                </h4>
                <span class="text-sm text-gray-600 dark:text-gray-400 font-lufga">
                  Integrity is the foundation upon which we operate, as we
                  believe in transparent and ethical practices, always putting
                  the best interests of our clients first.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoreValues;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../config';
import AdminHeader from './Components/AdminHeader';
import Aside from './Components/Aside';

const RegistrationDocuments = () => {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState([]);

  const openImageInNewTab =(imageUrl) => {
    // Use window.open() to open the image in a new tab
    window.open(imageUrl, '_blank');
  }
  useEffect(() => {
  fetch(`${BASE_URL}/api/getUserProfile/${id}`)
    .then(response => response.json())
    .then((data) => {
      if (data.Status === "Success") {
        setUserDetails(data.Profile[0]);
        console.log(data.Profile[0]);
        } else {
        console.log("error");
      }
    })
    .catch((error) => console.log(error));
}, [id]);

  return (
    <section>
      <div>
        <AdminHeader />
        <div className="flex overflow-hidden bg-white pt-16">
          <Aside />
          <div
            className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
            id="sidebarBackdrop"
          ></div>
          <div
            id="main-content"
            className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
          >
            <main>

            <div className="pt-6 px-4">


<div className="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
   <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
      <div className="flex items-center justify-between mb-4">
         <div className="flex-shrink-0">
            <h3 className="text-xl font-bold text-gray-900 mb-2">Registration Documents</h3>
         </div>

      </div>
      <hr/>
      <div id="main-chart">
      <div className="space-y-2 lg:grid lg:grid-cols-2 sm:gap-3 xl:gap-5 lg:space-y-0">

      <div className="flex rounded-[20px]">
  <div className="w-full h-[300px] relative overflow-hidden bg-cover bg-no-repeat">
    <img
      src={`${BASE_URL}/${userDetails.passportPhoto}`}
      alt="image"
      onClick={() => openImageInNewTab(`${BASE_URL}/${userDetails.passportPhoto}`)}
      className="w-full h-full object-cover max-w-xs transition duration-300 ease-in-out hover:scale-110 cursor-pointer"
    />
    <div className="absolute top-0 left-0 p-2 bg-black bg-opacity-50 text-white font-semibold">
      Passport Photo
    </div>
  </div>
    </div>

    <div className="flex rounded-[20px]">
        <div className="w-full h-[300px] relative overflow-hidden bg-cover bg-no-repeat">
        <img
            src={`${BASE_URL}/${userDetails.IDFile}`}
            alt="image"
            onClick={() => openImageInNewTab(`${BASE_URL}/${userDetails.IDFile}`)}
            className="w-full h-full object-cover max-w-xs transition duration-300 ease-in-out hover:scale-110 cursor-pointer"
        />
        <div className="absolute top-0 left-0 p-2 bg-black bg-opacity-50 text-white font-semibold">
      National ID/ Passport
    </div>
        </div>
    </div>
    <div className="flex rounded-[20px]">
        <div className="w-full h-[300px] relative overflow-hidden bg-cover bg-no-repeat">
        <img
            src={`${BASE_URL}/${userDetails.KRAFile}`}
            alt="image"
            onClick={() => openImageInNewTab(`${BASE_URL}/${userDetails.KRAFile}`)}
            className="w-full h-full object-cover max-w-xs transition duration-300 ease-in-out hover:scale-110 cursor-pointer"
        />
        <div className="absolute top-0 left-0 p-2 bg-black bg-opacity-50 text-white font-semibold">
      KRA File
    </div>
        </div>
    </div>
    <div className="flex rounded-[20px]">
        <div className="w-full h-[300px] relative overflow-hidden bg-cover bg-no-repeat">
        <img
            src={`${BASE_URL}/${userDetails.titleDeed}`}
            alt="image"
            onClick={() => openImageInNewTab(`${BASE_URL}/${userDetails.titleDeed}`)}
            className="w-full h-full object-cover max-w-xs transition duration-300 ease-in-out hover:scale-110 cursor-pointer"
        />
        <div className="absolute top-0 left-0 p-2 bg-black bg-opacity-50 text-white font-semibold">
      Title Deed File
    </div>
</div>
    </div>

      </div>
      </div>
   </div>
   <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
      <div className="mb-4 flex items-center justify-between">
         <div>
            <h3 className="text-xl font-bold text-gray-900 mb-2">PDF Files</h3>
             </div>

      </div>
      <hr/>
      <div className="flex flex-col mt-2">
      <div className="mt-4">


      </div>
      </div>
   </div>
</div>
</div>

            </main>
            <p className="text-center text-sm text-gray-500 my-10">
              &copy; 2023 <a href="https://promittoltd.com" className="hover:underline" target="_blank">Promitto</a>. All rights reserved.
            </p>
          </div>
        </div>
        <script async defer src="https://buttons.github.io/buttons.js"></script>
        <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
      </div>
    </section>
  );
};

export default RegistrationDocuments;

import React, { useState, useRef, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import {useNavigate} from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { BASE_URL } from "../config";

const TestAdmin = () => {
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const [projectInfo, setProjectInfo] = useState([]);
  const [numberOfPhotos, setnumberOfPhotos] = useState([]);

 useEffect(()=>{
  const token = localStorage.getItem("token");
  if(token){
    const tokenPayload = jwtDecode(token);
    const id = tokenPayload.id;
    setUser(token);
    fetch(`${BASE_URL}/api/getClientProjectByClientId/${id}`)
            .then((response) => response.json())
            .then((data) => {
              if (data.Status === "Success") {
                console.log(data.ProjectDetails);
                setProjectInfo(data.ProjectDetails);
                if(data.ProjectDetails[0].project_images != null){
                  const ArrayData = JSON.parse(data.ProjectDetails[0].project_images);

                  setnumberOfPhotos(ArrayData.length);
                }else{
                  projectInfo = "0";
                }
              } else {
               return;
              }
            })
            .catch((error) => console.log(error));

  }else{
    navigate('/login')
  }
 })


  return (
    <main>
      <Header />
      <section class="lg:mx-14 mt-20">
        <div class="mx-auto mr-8 mt-8 max-w-screen-xl overflow-hidden md:pl-8 bg-white">
          <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
            <h2 className="mb-4 text-4xl text-[#F2B807] dark:text-white font-lufgaBold tracking-wide">
              Create an Account
            </h2>
            <p className="-mb-8 text-center font-bold text-[#707070] sm:text-[16px] lg:text-lg dark:text-gray-400 font-lufga">
              Become a home owner today by Enrolling as a member.

            </p>

          </div>
        </div>

        <div class="mx-auto my-8 max-w-screen-xl overflow-hidden md:pl-8 bg-[#FFFCF2]">
          {/* Title Section */}
          <div class="max-w-screen-md mx-auto items-center text-center lg:mb-12 mt-12">
          <p>{user}</p>
          {projectInfo.length === 0 ? (
                      <p>No data found</p>
                   ) : (
                     projectInfo.map((project) => (
                       <div key={project.id}>
                          <ul>
                             {JSON.parse(project.kyc).map((kycItem, index) => (
                               <li key={index} className="flex items-center text-gray-600 sm:text-[14px] dark:text-gray-400 font-lufga">
                               <strong>{kycItem.description} : </strong>
                               {kycItem.description_value}
                             </li>
                             ))}
                          </ul>


                       </div>
                       ))

                   )}
            <h2 className="mb-4 text-[30px] font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide">
              How you can Register?
            </h2>
            <p className="mb-5 text-center font-bold text-[#707070] sm:text-[18px] lg:text-[16px] dark:text-gray-400 font-lufga">
              There are 3 main ways a new member will be able to express their
              interest to <br /> join Promitto Homes
            </p>
          </div>
          <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 mx-8 mb-8">
            {/* <!-- Pricing Card --> */}
            <div
              class="flex flex-col max-w-lg p-6 mx-auto w-90 text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              data-aos="fade-right"
            >
              <h3 class="mt-6 text-6xl text-[#F2B807] font-lufgaBold">01</h3>
              <p class="text-gray-600 sm:text-sm dark:text-gray-500 font-lufga mb-8">
                Using your phone send a text to 0729 509 509 via WhatsApp.
              </p>
            </div>
            {/* <!-- Pricing Card --> */}
            <div
              class="flex flex-col max-w-lg p-6 mx-auto w-90 text-left text-gray-900 bg-[#F2B807] border rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              data-aos="fade-up"
            >
              <h3 class="mt-6 text-6xl text-[#010440] font-lufgaBold">02</h3>
              <p class="text-gray-600 sm:text-sm dark:text-gray-500 font-lufga mb-8">
                Log in to Promitto Website and go to new member page and follow
                the instructions.
              </p>
            </div>
            {/* <!-- Pricing Card --> */}
            <div
              class="flex flex-col max-w-lg p-6 mx-auto w-90 text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              data-aos="fade-left"
            >
              <h3 class="mt-6 text-6xl text-[#F2B807] font-lufgaBold">03</h3>
              <p class="text-gray-600 sm:text-sm dark:text-gray-500 font-lufga mb-8">
                Visit Promitto Office and follow the guidance from customer
                Service Attendant.
              </p>
            </div>
          </div>
        </div>

      </section>
      <Footer />
    </main>
  );
};

export default TestAdmin;

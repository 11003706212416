import React, { useEffect, useState } from "react";
import AOS from "aos";
import AdminHeader from "./Components/AdminHeader";
import Aside from "./Components/Aside";
import { Link, useNavigate} from 'react-router-dom';
import Cookies from "js-cookie";
import jwtDecode from 'jwt-decode';
import { BASE_URL} from '././../../config';

const AdminDashboard = () => {
   const [transactions, setTransactions] = useState([]);
   const [filteredTransactions, setFilteredTransactions] = useState([]);

   const [role, setRole] = useState('');
   const navigate = useNavigate();

   const getPayments = () =>{
      fetch(`${BASE_URL}/api/payments`)
            .then((response) => response.json())
            .then((data) => {
              if (data.Status === "Success") {
                console.log(data.Transactions);
                setTransactions(data.Transactions);
                setFilteredTransactions(data.Transactions);
              } else {
               return;
              }
            })
            .catch((error) => console.log(error));
   }

 useEffect(()=>{
   getPayments();
 })

   const convertToReadableDateTime = (dateTimeString) => {
      // Parse the input string into date and time components
      const year = dateTimeString.slice(0, 4);
      const month = dateTimeString.slice(4, 6);
      const day = dateTimeString.slice(6, 8);
      const hours = dateTimeString.slice(8, 10);
      const minutes = dateTimeString.slice(10, 12);
      const seconds = dateTimeString.slice(12, 14);

      // Create a Date object with the components
      const formattedDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);

      // Format the Date object into a readable string
      const options = { year: 'numeric', month: 'short', day: '2-digit' };
      return formattedDate.toLocaleDateString(undefined, options);
    };
  return (
<section>
<div>
  <AdminHeader />
   <div className="flex overflow-hidden bg-white pt-16">
      <Aside />
      <div className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
      <div id="main-content" className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
         <main>
            <div className="pt-6 px-4">

               <div className="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-4">
                  <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
                     <div className="flex items-center">
                        <div className="flex-shrink-0">
                           <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">23</span>
                           <h3 className="text-base font-normal text-gray-500">Active Projects</h3>
                        </div>
                        <div className="ml-5 w-0 flex items-center justify-end flex-1 text-green-500 text-base font-bold">
                           4.6%
                           <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                           </svg>
                        </div>
                     </div>
                  </div>
                  <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
                     <div className="flex items-center">
                        <div className="flex-shrink-0">
                           <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">76</span>
                           <h3 className="text-base font-normal text-gray-500">Current Clients</h3>
                        </div>
                        <div className="ml-5 w-0 flex items-center justify-end flex-1 text-green-500 text-base font-bold">
                           12.9%
                           <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                           </svg>
                        </div>
                     </div>
                  </div>
                  <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
                     <div className="flex items-center">
                        <div className="flex-shrink-0">
                           <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">4</span>
                           <h3 className="text-base font-normal text-gray-500">Average Monthly registrations</h3>
                        </div>
                        <div className="ml-5 w-0 flex items-center justify-end flex-1 text-red-500 text-base font-bold">
                           1.7%
                           <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                           </svg>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
                  <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
                     <div className="flex items-center justify-between mb-4">
                        <div className="flex-shrink-0">
                           <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">KES 345,385</span>
                           <h3 className="text-base font-normal text-gray-500">Payments this week</h3>
                        </div>
                        <div className="flex items-center justify-end flex-1 text-green-500 text-base font-bold">
                           12.5%
                           <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
                           </svg>
                        </div>
                     </div>
                     <div id="main-chart"></div>
                  </div>
                  <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
                     <div className="mb-4 flex items-center justify-between">
                        <div>
                           <h3 className="text-xl font-bold text-gray-900 mb-2">Latest Transactions</h3>
                           <span className="text-base font-normal text-gray-500">This is a list of latest transactions</span>
                        </div>
                        <div className="flex-shrink-0">
                           <Link to='/transactions' className="text-sm font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2">View all</Link>
                        </div>
                     </div>
                     <div className="flex flex-col mt-8">
                        <div className="overflow-x-auto rounded-lg">
                           <div className="align-middle inline-block min-w-full">
                              <div className="shadow overflow-hidden sm:rounded-lg">
                                 <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                       <tr>
                                          <th scope="col" className="p-4 text-left text-xs font-sm text-gray-500 uppercase tracking-normal">
                                             Transaction Ref
                                          </th>
                                          <th scope="col" className="p-4 text-left text-xs font-sm text-gray-500 uppercase tracking-normal">
                                             Amount
                                          </th>
                                          <th scope="col" className="p-4 text-left text-xs font-sm text-gray-500 uppercase tracking-normal">
                                             Date
                                          </th>
                                          <th scope="col" className="p-4 text-left text-xs font-sm text-gray-500 uppercase tracking-normal">
                                             Phone No.
                                          </th>

                                          <th scope="col" className="p-4 text-left text-xs font-sm text-gray-500 uppercase tracking-normal">
                                             ID/ Passport No.
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                       {transactions.length === 0 ? (
                                          <tr>
                                          <td
                                            colSpan="6"
                                            className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                                          >
                                            No data found
                                          </td>
                                        </tr>
                                       ) : (
                                          transactions.map((transaction, index) => (
                                       <tr key={index} className="bg-gray-50">
                                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900 rounded-lg rounded-left">
                                          {transaction.MpesaReceiptNumber}
                                          </td>
                                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                                          {transaction.Amount.toLocaleString()}
                                          </td>
                                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                                          {convertToReadableDateTime(transaction.TransactionDate)}
                                          </td>
                                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                                          {transaction.PhoneNumber}
                                          </td>
                                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                                          {transaction.ClientID}
                                          </td>

                                       </tr>
                                          ))
                                       )}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </main>
         <p className="text-center text-sm text-gray-500 my-10">
            &copy; 2023 <a href="https://promittoltd.com" className="hover:underline" target="_blank">Promitto</a>. All rights reserved.
         </p>
      </div>
   </div>
   <script async defer src="https://buttons.github.io/buttons.js"></script>
   <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
</div>
</section>

  );
};

export default AdminDashboard;

import backgroundImage from '../../assets/hero_image.png';
import { FaPhoneSquareAlt } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';
import './MainContent.css';
import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';

const MainContent = () => {
    useEffect(() => {
        AOS.init({ duration: 2000});
    },[]);
    const sectionStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImage})`,
    };
    return (
        <section className="md:mx-6 mt-4 topbanner bg-cover bg-opacity-50 dark:bg-gray-900 border md:rounded-lg border-gray-200" style={sectionStyle}>
            <div className="max-w-screen-xl px-4 pt-10 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-36 lg:grid-cols-12 lg:pt-28 flex">
                <div className="mr-auto mt-4 md:mt-12 place-self-center lg:col-span-7">
                    <h1 className="max-w-2xl mb-2 md:mb-4 text-2xl font-lufga text-center md:text-left font-bold leading-none tracking-wide md:text-3xl xl:text-[40px] dark:text-white text-gray-100" data-aos="fade-down">Welcome to Promitto and benefit from 70% construction financing</h1>
                    <p className="max-w-2xl py-2 md:py-6 mb-6 mr-4 font-weight-400 font-lufga text-white text-left lg:mb-8 md:text-lg lg:text-lg dark:text-gray-400" data-aos="flip-right">
                    We are an NCA licensed corporation providing 70% construction financing and project management services to plot owners in Kenya
                   </p>
                    <div className="space-y-8 sm:flex sm:space-y-0 space-x-4 md:space-x-8">
                        <Link to='/how-to-own' className="inline-flex items-center justify-center rounded-md bg-[#F2B807] px-3 md:px-5 py-2.5 md:py-3 text-center text-weight-500 text-xs md:text-sm font-lufga text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300" data-aos="fade-right">
                            Own a Home <BiSearch className="text-gray-100 ml-2 text-xs md:text-lg" />
                        </Link>
                        <Link to='/contact-us' className="inline-flex items-center justify-center px-3 md:px-5 py-2.5 md:py-3 text-xs md:text-sm font-lufga text-center text-gray-100 border border-gray-200 rounded-lg sm:w-auto hover:bg-[#F2B807] focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800" data-aos="fade-left">
                            Contact Us<FaPhoneSquareAlt className="text-[#F2B807] ml-2 text-[16px] md:text-[20px] hover:bg-gray-700" />
                        </Link>
                    </div>
                </div>
                 <div className="hidden lg:mt-0 lg:col-span-5 lg:flex rounded-lg text-center lg:justify-end" data-aos="fade-up">
                    <div className='flex'>
                        <div className="grid">
                            <iframe className="rounded-lg border flex max-w-[100%] border-[#F2B807]" title="vimeo-player" src="https://player.vimeo.com/video/844342757?h=e81c427b36&title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allowFullScreen></iframe>
                            <p className="mt-0 text-sm font-lufga text-white text-weigth-[800]">A quick word from our CEO</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MainContent;

import React, {useState, useEffect, useRef} from "react";
import { useParams } from 'react-router-dom';
import FileUpload from "./file-upload.component";
import { BASE_URL } from "../../../config";
import {BiEdit} from 'react-icons/bi';
import {MdOutlineAddAPhoto} from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UploadDrawings = () => {
    const { id } = useParams();
    const [projectInfo, setProjectInfo] = useState([]);
    const [uploadSuccess, setUploadSuccess] = useState(false);


    const [newUserInfo, setNewUserInfo] = useState({
        profileImages: []
      });

      const updateUploadedFiles = (files) =>
        setNewUserInfo({ ...newUserInfo, profileImages: files });

        const handleSubmit = async (event) => {
            event.preventDefault();

             // Check if there are no files to upload
            if (newUserInfo.profileImages.length === 0) {
                // Show an alert to the user
                alert("Please upload at least one file before submitting.");
                return; // Prevent further execution
            }

            try {
              // Create a FormData object to send files to the API
              const formData = new FormData();
              newUserInfo.profileImages.forEach((file) => {
                formData.append("imageFiles", file);
              });

              // Send the FormData to your Node.js API
              const response = await fetch(`${BASE_URL}/api/uploadImageDrawings/${id}`, {
                method: "PUT",
                body: formData,
              });

              if (response.ok) {
                // Handle success, e.g., show a success message
                setUploadSuccess(true);
                toast.success("Upload was successfull", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  });

              } else {
                // Handle errors, e.g., show an error message
                console.error("Failed to upload files.");
              }
            } catch (error) {
              console.error("Error:", error);
            }
          };

          const getProjectDetails = () => {
            fetch(`${BASE_URL}/api/getClientProjectByClientId/${id}`)
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.Status === "Success") {
                      //console.log(data.ProjectDetails);
                      if(data.ProjectDetails[0].drawings != null){
                        const ArrayData = JSON.parse(data.ProjectDetails[0].drawings);

                        setProjectInfo(ArrayData.length);
                      }else{
                        //projectInfo = "0";
                        return;
                      }

                    } else {
                     return;
                    }
                  })
                  .catch((error) => console.log(error));
          }
          useEffect(() => {
              getProjectDetails();

              const intervalId = setInterval(getProjectDetails, 2000);

              // Clean up the interval when the component unmounts.
              return () => clearInterval(intervalId);
          }, [id]);



    return (
        <>
        <div class="max-w-lg">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                              Drawings
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                                Please select the buttons below to upload the
                                required data.
                              </h3>
                              <div className="max-w-screen-md mx-auto text-center">
                                {projectInfo.length === 0 ? (
                                  <p class="text-center text-sm leading-6 mb-2 text-gray-500 hover:text-gray-600">
                                    You have not added any data
                                  </p>
                                ) : (
                                  <p class="text-center text-sm leading-6 mb-2 text-gray-500 hover:text-gray-600">
                                    Add more data
                                  </p>
                                )}
                                <div className="flex justify-center gap-12">
                                  <button
                                    data-te-toggle="modal"
                                    data-te-target="#drawings"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    className="flex items-center rounded-[5px] bg-[#bac4ed] px-5 py-2.5 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
                                  >
                                    <MdOutlineAddAPhoto className="ml-4 mr-2 text-sm" />{" "}
                                    <span className="mr-8">Add File</span>
                                  </button>
                                </div>
                              </div>
                            </div>
        </div>
        <div
            data-te-modal-init
            class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
            id="drawings"
            tabindex="-1"
            aria-labelledby="exampleModalXlLabel"
            aria-modal="true"
            role="dialog">
                <div
                    data-te-modal-dialog-ref
                    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]">
                    <div
                        class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                        <div
                            class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

                            <h5
                                class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                id="exampleModalXlLabel">
                                Upload Drawings Photos or PDF's
                            </h5>
                            <button
                                type="button"
                                class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                data-te-modal-dismiss
                                aria-label="Close">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="h-6 w-6">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        {/* <!--Modal body--> */}
                        <div class="relative p-4">
                        <form onSubmit={handleSubmit}>
        <FileUpload
          accept=".jpg,.png,.jpeg,.pdf"
          label="Drawing Image(s)"
          multiple
          updateFilesCb={updateUploadedFiles}
          resetOnSuccess={uploadSuccess}
        />
        <button type="submit" class="px-3 py-2 text-xs font-medium text-center text-black bg-[#F2B807] rounded-sm hover:bg-[#F2B807] focus:ring-4 focus:outline-none focus:ring-[#F2B807] dark:bg-[#F2B807] dark:hover:bg-[#F2B807] dark:focus:ring-[#F2B807]">Upload Photos</button>
         </form>

                        </div>
                    </div>
                </div>
            </div></>

    );
}

export default UploadDrawings;
import React, {useState} from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import image1 from "../assets/account/LOGIN_PAGE_IMAGE.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL} from '../config';


const AdminLogin = () => {
    const username = localStorage.getItem("username");
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    axios.defaults.withCredentials = true;
    let navigate = useNavigate();
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
                await axios.post(`${BASE_URL}/api/adminLogin`, { username, password })
                .then(res => {
                    if(res.data.Status === "Success"){
                        localStorage.setItem("token", res.data.token)
                        localStorage.setItem("details", res.data.User);
                        if(res.data.Role === "admin" || res.data.Role === "pmanager"){
                            navigate('/clients');
                        }else{
                            navigate('/admin-dashboard');
                        }

                    }else{
                        setErrorMessage(res.data.Message);
                    }
                })
                .catch(err => console.log(err));
          } catch (error) {
            console.error('Login failed:', error);
          }
      };
  const sectionStyle = {
    backgroundImage: `url(${image1})`,
};
    return (

        <main>
            <Header />
            <section className="bg-white dark:bg-gray-900 lg:mx-14 sm:mx-8 my-28">
            <div className="bg-white dark:bg-gray-900">
    <div className="flex justify-center h-[500px] rounded-[20px] border shadow-lg shadow-[#efd27b] mx-8">


        <div className="flex items-center w-full max-w-md px-8 md:px-2 mx-auto lg:w-2/6">
            <div className="flex-1">

                <div className="mt-8 lg:-ml-16">
                {errorMessage && (
                    <div class="mb-3 inline-flex w-full items-center rounded-sm bg-danger-100 px-6 py-3 text-base text-danger-700" role="alert">
                        <span class="mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5">
                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                            </svg>
                        </span>
                        {errorMessage}
                    </div>
                )}

                    <form onSubmit={handleLogin}>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-lg text-gray-500 font-lufgaBold dark:text-gray-200">Enter Your Password</label>
                            <input type="password"
                             value={password}
                             onChange={(e) => setPassword(e.target.value)}
                           placeholder="Password" className="block w-full px-4 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border text-sm border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" required/>
                        </div>


                        <div className="mt-8 md:flex md:items-center">
                        <button type="submit" className="flex items-center rounded-lg bg-[#F2B807] px-5 py-2.5 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">
                            Submit
                        </button>

                        <p className="text-sm inline-block mt-4 text-center  md:mt-0 md:mx-6 text-gray-400">Forgot your password? <Link to="/forgot-password" className="text-[#F2B807] focus:outline-none font-lufgaBold focus:underline hover:underline">Reset here</Link>.</p>
                    </div>

                    </form>


                </div>
            </div>
        </div>

        <div className="hidden bg-cover lg:block lg:w-1/2 rounded-[20px] rounded-bl-none rounded-tl-none border" style={sectionStyle}>
            <div className="flex items-center h-full px-20">
                <div>
                    {/* <h2 class="text-2xl font-bold text-white sm:text-3xl">Meraki UI</h2> */}
                     {/* <img src={image1} alt="" class="h-[400px]" /> */}
                </div>
            </div>
        </div>
    </div>
</div>

            </section>
            <Footer />
        </main>
    );
}

export default AdminLogin;
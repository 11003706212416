import React, { useState, useEffect, useRef, Fragment } from "react";
import logo from "../../../assets/promitto_logo_transparent.png";
import { XMarkIcon } from '@heroicons/react/24/outline';
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { BASE_URL } from "../../../config";
import jwtDecode from "jwt-decode";
import { FaUserCircle } from "react-icons/fa";
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import {TbArrowsExchange2} from 'react-icons/tb';
import {FaUsers} from 'react-icons/fa';

const AdminHeader = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [role, setRole] = useState("");
  const menuRef = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  // Function to toggle the menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to close the menu when clicking outside
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (windowWidth > 768) {
      setIsMenuOpen(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const tokenPayload = jwtDecode(token);
      setRole(tokenPayload.role);
    }
    // Attach a click event listener to the document to close the menu when clicking outside
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", handleResize);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
      window.addEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    axios
      .get(`${BASE_URL}/api/logout`)
      .then((res) => {
        if (res.data.Status === "Success") {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          navigate("/login");
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {/* Mobile menu */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-sm font-lufgaBold text-gray-900">Menu</h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex-1 px-3 bg-white divide-y space-y-1">
                  <ul className="space-y-2 pb-2">
                     {role === "super" && (
                        <>
                        <li>
                        <Link to="/admin-dashboard" className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group">
                           <svg className="w-6 h-6 text-gray-500 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                              <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                           </svg>
                           <span className="ml-3">Dashboard</span>
                        </Link>
                     </li>
                     <li>
                            <Link to='/managers' className="text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center p-2 group ">
                               <svg className="w-6 h-6 text-gray-500 flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path>
                               </svg>
                               <span className="ml-3 flex-1 whitespace-nowrap">Managers</span>
                            </Link>
                         </li>
                         <li>
                               <Link to='/project-management' className="text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center p-2 group ">
                               <svg className="w-6 h-6 text-gray-500 flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                           <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
                        </svg>
                                  <span className="ml-3 flex-1 whitespace-nowrap">Product Management</span>
                               </Link>
                            </li>

                         </>
                     )}
                     {role === "basic" && (
                        <>
                        <li>
                        <Link to="/dashboard" className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group">
                           <svg className="w-6 h-6 text-gray-500 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                              <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                           </svg>
                           <span className="ml-3">Project Status</span>
                        </Link>
                     </li></>
                     )}
                     {(role === "admin" || role === "super" || role === "pmanager") && (
                        <><li>
                               <Link to='/clients' className="text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center p-2 group ">
                                  <FaUsers />
                                  <span className="ml-3 flex-1 whitespace-nowrap">Client Management</span>
                               </Link>
                            </li>

                           </>
                     )}
                     {(role === "admin" || role === "super") && (
                        <>

                     <li>
                        <Link to='/transactions' className="text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 flex items-center p-2 group ">

                           <TbArrowsExchange2 className="text-xl" />
                           <span className="ml-3 flex-1 whitespace-nowrap">Transactions</span>
                        </Link>
                     </li></>
                     )}



                  </ul>

               </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      <nav className="bg-white border-b border-gray-200 fixed z-30 w-full">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                id="toggleSidebarMobile"
                aria-expanded="true"
                aria-controls="sidebar"
                className="lg:hidden mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <svg
                  id="toggleSidebarMobileHamburger"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  id="toggleSidebarMobileClose"
                  className="w-6 h-6 hidden"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <Link to="/" className="text-xl font-bold flex items-center lg:ml-2.5">
                <img src={logo} className="h-12 mr-2" alt="Windster Logo" />
                {role === "admin" && (
                  <span className="self-center whitespace-nowrap">Administration</span>
                )}
              </Link>
            </div>

            <div
              className="relative"
              data-te-dropdown-ref
              data-te-dropdown-alignment="end"
            >
              {/* <!-- Second dropdown trigger --> */}
              <a
                className="hidden-arrow flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none"
                href="#"
                id="dropdownMenuButton2"
                role="button"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                <FaUserCircle onClick={toggleMenu} className="h-12 w-12" />
                {/* <img
                    onClick={toggleMenu}
                    src="https://tecdn.b-cdn.net/img/new/avatars/2.jpg"
                    class="rounded-full h-12 w-12"
                    alt=""
                    loading="lazy" /> */}
              </a>

              {/* Mobile menu content */}
              {isMenuOpen && (
                <div
                  ref={menuRef}
                  className="absolute right-0 mt-2 w-48 bg-white border-[#F2B807] shadow-lg rounded-lg z-10"
                >
                  <ul>
                    <li>
                      <a href="/#" className="block px-4 py-2 text-gray-800 hover:bg-[#F2B807]">
                        Settings
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={handleLogout}
                        className="block px-4 py-2 text-gray-800 hover:bg-[#F2B807] cursor-pointer"
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AdminHeader;

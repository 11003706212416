export const testimonialData = [
    {
      id: 1,
      name: 'Helena Wakarima Njogu',
      testimonial: 'Iam Happy to share my wonderful experience with Promitto Ltd, the exceptional construction company that made my dream home a reality in'
    },
    {
      id: 2,
      name: 'James Kinyanjui Bubi ',
      testimonial: 'My experience with Promitto Ltd has been nothing short of exceptional. I had the pleasure of working with them to construct my '
    },
    {
      id: 3,
      name: 'Simon Mbugua',
      testimonial: 'What sets Promitto Ltd apart is not only their construction expertise but also their commitment to customer satisfaction. They kept me informed at '
    },
    {
      id: 4,
      name: 'Margaret Wangari Kigotho',
      testimonial: 'I can\'t express how grateful I am to have had the opportunity to work with Promitto Ltd on the finishings for my mother\'s 3-bedroom bungalow'
    }
  ];
import React, { useEffect, useState } from "react";
import AOS from "aos";
import { BiEdit } from "react-icons/bi";
import {AiOutlineEye} from 'react-icons/ai';
import AdminHeader from "./Components/AdminHeader";
import Aside from "./Components/Aside";
import profileImage from "../../assets/about-us/Joseph.png";
import { useParams } from "react-router-dom";
import { MdNotificationsActive, MdSave } from "react-icons/md";
import { TbHistoryOff } from "react-icons/tb";
import { FaHistory } from "react-icons/fa";
import { Modal, Ripple, initTE } from "tw-elements";
import SiteVisit from "./ClientProjectsComponents/SiteVisit";
import ProjectPhotos from "./ClientProjectsComponents/ProjectPhotos";
import { BASE_URL } from "../../config";
import FinancialInfo from "./ClientProjectsComponents/FinancialInfo";
import ConstructionUpdate from "./ClientProjectsComponents/ConstructionUpdate";
import UploadDrawings from "./ClientProjectsComponents/UploadDrawings";
import BQ from "./ClientProjectsComponents/BQ";
import LoanAppStatus from "./ClientProjectsComponents/LoanAppStatus";
import GroundBreaking from "./ClientProjectsComponents/GroundBreaking";
import axios from "axios";
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import 'react-toastify/dist/ReactToastify.css';

const ClientProject = () => {
  useEffect(() =>{
    initTE({ Modal, Ripple });
  })
  const [client, setClient] = useState("");
  const [manager, setManger] = useState("");
  const { id } = useParams();
  const [displayButton, setDisplayButton] = useState(true);
  const [displayCard, setDisplayCard] = useState(false);
  const [projectInfo, setProjectInfo] = useState([]);
  const [editKYCData, setEditKYCData] = useState([]);
  const [showProjecthistory, setShowProjectHistory] = useState(false);

  const [inputFields, setInputFields] = useState([
    { description: "", description_value: "" },
  ]);

  const isButtonDisabled = inputFields.some(
    field => field.description === '' || field.description_value === ''
  );

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const addFields = (event) => {
    event.preventDefault();
    let newfield = { description: "", description_value: "" };

    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const submit = (e) => {
    e.preventDefault();
    console.log(inputFields);
    //localStorage.setItem("data", inputFields);
    localStorage.setItem("kyc_info", JSON.stringify(inputFields));
  };

  // Use useEffect to save inputFields to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("kyc_info", JSON.stringify(inputFields));
  }, [inputFields]);

  const clearFields = (e) => {
    e.preventDefault();
    setInputFields([{ description: "", description_value: "" }]);
    localStorage.removeItem("kyc_info");
  };

  const GetManagers = () => {
    fetch(`${BASE_URL}/api/getAdmins`)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          setManger(data.Managers[0]);
        } else {
          //alert("Error");
          //console.log(data.Message);
        }
      })
      .catch((error) => console.log(error));
  };

  const GetUserProfile =() =>{
    fetch(`${BASE_URL}/api/getUserProfile/${id}`)
    .then((response) => response.json())
    .then((data) => {
      if (data.Status === "Success") {
        console.log("works")
        console.log(data.Profile[0]);
        setClient(data.Profile[0]);
      } else {
        console.log("error");
      }
    })
    .catch((error) => console.log(error));
  }

  const getProjectDetails = () => {
    fetch(`${BASE_URL}/api/getClientProjectByClientId/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          //console.log(data.ProjectDetails);
          setDisplayButton(false);
          setDisplayCard(true);
          if (data.ProjectDetails[0].kyc != null) {
            setProjectInfo(data.ProjectDetails);
            setShowProjectHistory(true);
            //setKycData(JSON.parse(data.ProjectDetails[0].kyc));
            setEditKYCData(JSON.parse(data.ProjectDetails[0].kyc));
            //console.log(JSON.parse(data.ProjectDetails[0].kyc));
          } else {
            return;
          }
        } else {
          return;
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() =>{
    GetUserProfile();
  },[id])
  useEffect(() => {
    AOS.init({ duration: 2000 });
    getProjectDetails();
    GetManagers();
    //const intervalId = setInterval(getProjectDetails, 2000);

    // Clean up the interval when the component unmounts.
    //return () => clearInterval(intervalId);
  }, [id]);

  const submitKYC = async (e) => {
    e.preventDefault();
    const kycinfo = localStorage.getItem("kyc_info");
    const data = { kycinfo };
    console.log(data);
    await fetch(`${BASE_URL}/api/submitKYCInfo/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          setShowProjectHistory(true);
          localStorage.removeItem("kyc_info");
          setInputFields([{ description: "", description_value: "" }]);
          toast.success(data.Message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            });
        } else {
          alert(data.Status);
          console.log(data.Status[0]);
        }
      });
  };
  const submitNewProject = async (e) => {
    e.preventDefault();
    const client_id = id;
    const manager_id = manager.ID;
    const data = { client_id, manager_id };
    console.log(data);
    await fetch(`${BASE_URL}/api/createClientProject`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          setDisplayButton(false);
          setDisplayCard(true);
          Swal.fire({
            icon: 'success',
            title: data.Message,
            showConfirmButton: true,
            confirmButtonText: 'OK',
            confirmButtonColor: '#F2B807',
        });

          //alert(data.Message);
        } else {
          alert(data.Status);
          console.log(data.Status[0]);
        }
      });
  };

  const sendNotification = async (e) => {
    e.preventDefault();
    const client_email = client.Email;
    const message = `<strong>${client.FullName}</strong>, <br>There is a new Update to your project. Please login to your account to view details`;
    const subject = "PROJECT UPDATE";
    const title = "Password Reset Link";
    const data = { client_email, subject, message, title };
    await fetch(`${BASE_URL}/api/send-notifaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          toast.success(data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            });
        } else {
          alert("Failed to send notification. Please try again");
        }
      });
  };

  const reloadDetails = () =>{
    getProjectDetails();
  }

  const handleKYCInputChange = (index, key, value) => {
    const updatedKYCData = [...editKYCData];
    updatedKYCData[index][key] = value;
    setEditKYCData(updatedKYCData);
  };



  //send the update
  const updateKyc = async () => {
    // const kyc = {editKYCData};
    //console.log('Request Payload:', kyc);
    try {
      const response = await axios.put(`${BASE_URL}/api/editKYCInfo/${id}`, {kyc: editKYCData});
      if(response.data.Status === 'Success'){
        toast.success(response.data.Message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          });
      }else{
        alert("An error occurred, try again")
      }

      // Handle success or update UI as needed
    } catch (error) {
      console.error(error.response.data);
      // Handle error or update UI as needed
    }
  };


  return (
    <section>
      <div>
        <AdminHeader />
        <div className="flex overflow-hidden bg-white pt-16">
          <Aside />
          <div
            className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
            id="sidebarBackdrop"
          ></div>
          <div
            id="main-content"
            className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
          >
            <main>
              <div className="pt-6 px-4">
                <div className="flex flex-col mt-6">
                  <div className="flex items-center gap-[10px] mb-10">
                    <div className="w-10 h-10 border-gray-100 shadow dark:border-gray-600 rounded-2xl flex items-center justify-center overflow-hidden">
                      {/* Replace the 'path/to/image.png' with the actual path to your image file */}
                      <img
                        src={profileImage}
                        alt="Profile Image"
                        className="rounded-full object-cover w-full h-full"
                      />
                    </div>
                    <div className="leading-[25px]">
                      <h2 className="text-[18px] font-lufgaBold text-[#F2B807]">
                        {manager.FullName}
                      </h2>
                      <p
                        className="text-gray-600 sm:text-[14px] dark:text-gray-400 font-lufga"
                        data-aos="fade-up"
                      >
                        {manager.managerTitle}
                      </p>
                    </div>
                  </div>
                  {displayButton && (
                    <div className="col-12 ml-2 mb-4">
                      <button
                        onClick={submitNewProject}
                        className="flex items-center rounded-lg bg-[#F2B807] px-5 py-2.5 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
                      >
                        Begin Process
                      </button>
                    </div>
                  )}

                  {displayCard && (
                    <section class="bg-white dark:bg-gray-900 border-[2px] border-[#f5d77b] rounded-sm bg-gradient-to-b from-[#f9f4e6] via-[#faf9f5] to-transparent">
                      <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-10 lg:px-6">
                        <div class="max-w-screen-xl mb-8 text-left lg:mb-12">
                          <div class="grid grid-cols-2 md:gap-64 place-content-between h-12">
                            <div className="flex items-center gap-[10px]">
                              <div className="w-10 h-10 border-gray-100 shadow dark:border-gray-600 rounded-2xl flex items-center justify-center overflow-hidden">
                                {/* Replace the 'path/to/image.png' with the actual path to your image file */}
                                <img
                                  src={profileImage}
                                  alt="Profile Image"
                                  className="rounded-full object-cover w-full h-full"
                                />
                              </div>
                              <div className="leading-[25px]">
                                <p
                                  className="text-gray-600 sm:text-[14px] dark:text-gray-400 font-lufga"
                                  data-aos="fade-up"
                                >
                                  Client Name
                                </p>
                                <h2 className="text-[18px] font-lufgaBold text-gray-600">
                                  {client.FullName}
                                </h2>
                                <p
                                  className="text-gray-600 sm:text-[14px] dark:text-gray-400 font-lufga"
                                  data-aos="fade-up"
                                >
                                  {client.City}
                                </p>
                              </div>
                            </div>
                            <div className="md: ml-20">
                              <button
                                onClick={sendNotification}
                                className="flex items-center rounded-[5px] bg-[#F2B807] px-5 py-2.5 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
                              >
                                <MdNotificationsActive className="mr-2 text-lg" />Send Notification
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-4 lg:space-y-0">
                          {/* <!-- KYC Card --> */}
                          <div class="max-w-lg relative">
                            <div class="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg">
                              {/* Card content */}
                              <h1 class="my-1 text-center text-xl font-bold leading-8 text-gray-900">
                              Project Details
                              </h1>
                              <h3 class="font-lg text-semibold text-center leading-6 text-gray-600">
                                Please upload the required data below.
                              </h3>
                              <div className="max-w-screen-md mx-auto text-center mt-4">
                                <div className="flex justify-center gap-12">
                                  {/* Buttons */}
                                  <button
                                    data-te-toggle="modal"
                                    data-te-target="#projectphotos"
                                    data-te-ripple-init
                                    data-te-ripple-color="light"
                                    className="flex items-center rounded-[5px] bg-[#bac4ed] px-5 py-2.5 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none"
                                  >
                                    <BiEdit className="ml-4 mr-2 text-sm" />{" "}
                                    <span className="mr-8">Add Text</span>
                                  </button>
                                </div>

                                {/* Icon in the top right */}

                                <div className="absolute top-0 right-0 m-4">
                                {!showProjecthistory ? (
                                    <TbHistoryOff
                                    className="text-xl text-gray-800" />
                                    ):(
                                      <FaHistory
                                      onClick={reloadDetails}
                                      data-te-toggle="modal"
                                      data-te-target="#projectHistory"
                                      className="text-xl text-red-700 hover:cursor-pointer" />
                                    )}

                                </div>
                              </div>
                            </div>
                          </div>
                          <div
  data-te-modal-init
  class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="projectHistory"
  tabindex="-1"
  aria-labelledby="exampleModalXlLabel"
  aria-modal="true"
  role="dialog">
  <div
    data-te-modal-dialog-ref
    class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]">
    <div
      class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
      <div
        class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

        <h5
          class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
          id="exampleModalXlLabel">
          Edit Project Details History
        </h5>
        <button
          type="button"
          class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss
          aria-label="Close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div className="relative p-4">
  <div className="container mx-auto p-4">
    <form onSubmit={(e) => e.preventDefault()}>
    {editKYCData.map((item, index) => (
            <div key={index}>
             <div className="grid gap-3 md:grid-cols-3">
  <div className="flex items-center mt-2">
    <label className="mr-2">Description:</label>
    <input
      type="text"
      value={item.description}
      onChange={(e) => handleKYCInputChange(index, 'description', e.target.value)}
      id="small-input"
      className="w-3/4 p-2 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 sm:text-xs dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
    />
  </div>

  <div className="flex items-center mt-2 col-span-2">
    <label className="mr-2">Description Value:</label>
    <input
      type="text"
      value={item.description_value}
      onChange={(e) => handleKYCInputChange(index, 'description_value', e.target.value)}
      className="w-3/4 p-2 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 sm:text-xs dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
    />
  </div>
</div>
            </div>
          ))}
      <button
        onClick={updateKyc}
          class="flex items-center mt-3 rounded-sm bg-gray-700 px-2 py-2 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none"
        >
          <MdSave className="text-gray-100 mr-2 text-lg" /> Save
        </button>

    </form>
  </div>
</div>

    </div>
  </div>
                        </div>
                          {/* <!-- Site Visit Card --> */}
                          <SiteVisit />
                          {/* <!-- Drawings Card --> */}
                          <UploadDrawings />
                          {/* <!-- BQ Preparation Card --> */}
                          <BQ />
                          {/* <!-- Financial Information Card --> */}
                          <FinancialInfo />
                          {/* <!-- Ground Breaking Card --> */}
                          <GroundBreaking />

                          {/* <!-- Construction Updates --> */}
                          <ConstructionUpdate />
                          {/* <!-- Site Clearing Card --> */}

                          {/* <!-- Photos Card --> */}
                          <ProjectPhotos />

                          {/* <!-- Loan Card --> */}
                          <LoanAppStatus />

                          <div
                            data-te-modal-init
                            class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
                            id="projectphotos"
                            tabindex="-1"
                            aria-labelledby="exampleModalXlLabel"
                            aria-modal="true"
                            role="dialog"
                          >
                            <div
                              data-te-modal-dialog-ref
                              class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]"
                            >
                              <div class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                                <div class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                                  <h5
                                    class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                                    id="exampleModalXlLabel"
                                  >
                                    Project Details
                                  </h5>
                                  <button
                                    type="button"
                                    class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                    data-te-modal-dismiss
                                    aria-label="Close"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="h-6 w-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </button>
                                </div>

                                {/* <!--Modal body--> */}
                                <div class="relative p-4">
                                  <form onClick={submit}>
                                    {inputFields.map((input, index) => {
                                      return (
                                        <>
                                          <div key={index}>
                                            <input
                                              name="description"
                                              placeholder="description"
                                              className="border border-gray-500 mr-4 px-4 py-2 mt-2 text-gray-700 bg-white rounded-sm"
                                              value={input.description}
                                              autoComplete="off"
                                              onChange={(event) =>
                                                handleFormChange(index, event)
                                              }
                                              required
                                            />
                                            <input
                                              name="description_value"
                                              placeholder="value"
                                              className="border border-gray-500 mr-4 px-4 py-2 mt-2 text-gray-700 bg-white rounded-sm"
                                              value={input.description_value}
                                              autoComplete="off"
                                              onChange={(event) =>
                                                handleFormChange(index, event)
                                              }
                                              required
                                            />
                                            {index != 0 && (
                                              <button
                                                className="bg-red-500 text-white py-1.5 px-4 ml-2 rounded"
                                                onClick={() =>
                                                  removeFields(index)
                                                }
                                              >
                                                Remove
                                              </button>
                                            )}
                                          </div>
                                        </>
                                      );
                                    })}
                                    <button
                                      className="bg-[#F2B807] text-white py-1.5 mr-2 px-4 rounded"
                                      onClick={addFields}
                                    >
                                      Add More
                                    </button>
                                    <button
                                      className="bg-red-500 text-white py-1.5 mr-2 px-4 rounded mt-4"
                                      onClick={clearFields}
                                    >
                                      Reset
                                    </button>


                                    <button
                                      disabled={isButtonDisabled}
                                      onClick={submitKYC}
                                      className={`py-1.5 px-4 rounded mt-4 ${
                                        isButtonDisabled
                                          ? 'bg-gray-400 cursor-not-allowed'
                                          : 'bg-green-500 hover:bg-green-600'
                                      } text-white`}
                                    >
                                      Submit data
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </main>
            <p className="text-center text-sm text-gray-500 my-10">
              &copy; 2023{" "}
              <a
                href="https://promittoltd.com"
                className="hover:underline"
                target="_blank"
              >
                Promitto
              </a>
              . All rights reserved.
            </p>
          </div>
        </div>
        <script async defer src="https://buttons.github.io/buttons.js"></script>
        <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
      </div>
    </section>
  );
};

export default ClientProject;

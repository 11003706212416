import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "../App.css";
import About from "../components/AboutUs/About";
import OurTeam from "../components/AboutUs/OurTeam";
import CoreValues from "../components/AboutUs/CoreValues";
import InvestmentOpportunities from "../components/AboutUs/InvestmentOpportunities";

const AboutUs = () => {

  return (
    <main>
      <Header />
        <About />
        <OurTeam />
        <CoreValues />
        <InvestmentOpportunities />
      <Footer />
    </main>
  );
};
export default AboutUs;

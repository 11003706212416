import React, { useEffect } from "react";

import { GiTakeMyMoney } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";
import { GiCornerFlag } from "react-icons/gi";
import { BsArrowRightCircle } from 'react-icons/bs';
import { FaRegEye } from 'react-icons/fa';

import house1 from '../../assets/how-to-own/house1.png';
import house2 from '../../assets/how-to-own/house2.png';
import house3 from '../../assets/how-to-own/house3.png';
import image1 from '../../assets/how-to-own/Path 14.png';

import AOS from "aos";
const HouseDesignFeatures = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (

    <section class="bg-white  lg:mx-14 sm:mx-8 mt-8 shadow dark:border-gray-600 dark:bg-gray-800 border rounded-[20px] border-gray-200 mb-8">
      <div class="max-w-screen-xl px-2 py-4 mx-auto lg:py-2 lg:px-2">

        <div class="space-y-2 lg:grid lg:grid-cols-2 sm:gap-2 xl:gap-2 lg:space-y-0" data-aos="fade-right">
          {/* <!-- Pricing Card 1--> */}
          <div class="flex flex-col max-w-[650px] max-h-full p-6 text-left text-gray-900 bg-white ">
             <div className="lg:pt-8 sm:pt-8">
             <h2 className="mb-4 text-xl text-[#010440] dark:text-white font-lufgaBold tracking-wide" data-aos="fade-down">
             Two Bedroom House.
                        </h2>
            {/* <p class=" text-[#010440] sm:text-[16px] mb-4 dark:text-gray-600 font-lufgaBold mr-8">
            Cost
            </p>
            <p class=" text-[#212121] sm:text-[13px] mb-4 dark:text-gray-600 font-lufga mr-8">
            2 Bedroom bungalow cost <b>KES 2.6m</b> monthly instalments <b>35,581per month</b>
            </p> */}
            <p class=" text-[#010440] sm:text-[16px] mb-4 dark:text-gray-600 font-lufgaBold mr-8">
            Features
            </p>

            <p class=" text-[#212121] sm:text-[13px] mb-4 dark:text-gray-600 font-lufga mr-8">
            Master Ensuite Bedroom with walk-in closet, 1 bedroom with a common washroom, Dining Area, Living Room, Open Plan Kitchen, Built-in Wardrobes for the two bedrooms, Pantry, 500 litres internal storage tank & 5, 000 litres reserve tank, Foyer at the main entrance, Septic tank, Tiled floors, 2 steel doors, A perimeter wall equivalent to 50 by 100m, steel gate, wired with provisions of security lights, internet, CCTV and Cable TV.
            </p>



             </div>

          </div>
            {/* <!-- Pricing Card 2--> */}
            <div class="flex flex-col max-w-[800px] max-h-full p-6 text-left text-gray-900 bg-white" data-aos="fade-left">
             <div className="lg:pt-8 sm:pt-8 -m-4" data-aos="fade-left">
                         <img
                alt="gallery"
                class="h-75 w-full rounded-[20px] object-cover object-center"
                src={house1} />

             </div>

          </div>

        </div>

        <div class="space-y-2 lg:grid lg:grid-cols-2 sm:gap-2 xl:gap-2 lg:space-y-0" data-aos="fade-left">

            {/* <!-- Pricing Card 2--> */}
            <div class="flex flex-col max-w-[800px] max-h-full p-6 text-left text-gray-900 bg-white">
             <div className="lg:pt-8 sm:pt-8 -m-4">
                         <img
                alt="gallery"
                class="h-75 w-full rounded-[20px] object-cover object-center"
                src={house2} />

             </div>

          </div>
            {/* <!-- Pricing Card 1--> */}
          <div class="flex flex-col max-w-[650px] max-h-full p-6 text-left text-gray-900 bg-white ">
             <div className="lg:pt-8 sm:pt-8">
             <h2 className="mb-4 text-xl text-[#010440] dark:text-white font-lufgaBold tracking-wide" data-aos="fade-down">
             Three Bedroom House.
                        </h2>
            {/* <p class=" text-[#010440] sm:text-[16px] mb-4 dark:text-gray-600 font-lufgaBold mr-8">
            Cost
            </p>
            <p class=" text-[#212121] sm:text-[13px] mb-4 dark:text-gray-600 font-lufga mr-8">
            A three Bedroom Bungalow will cost <b>KES3.7m</b>, monthly instalments of <b>KES. 48,000 per month.</b>
            </p> */}
            <p class=" text-[#010440] sm:text-[16px] mb-4 dark:text-gray-600 font-lufgaBold mr-8">
            Features
            </p>

            <p class=" text-[#212121] sm:text-[13px] mb-4 dark:text-gray-600 font-lufga mr-8">
            Master Ensuite Bedroom with walk-in closet, 2 bedrooms with a common washroom, Dining Area, Living Room, Open Plan Kitchen, Built-in Wardrobes for the two bedrooms, Pantry, 500 litres internal storage tank & 5, 000 litres reserve tank, Foyer at the main entrance, Septic tank, Tiled floors, 2 steel doors, A perimeter wall equivalent to 50 by 100m, steel gate, wired with provisions of security lights, internet, CCTV and Cable TV.
            </p>



             </div>

          </div>
        </div>

        <div class="space-y-2 lg:grid lg:grid-cols-2 sm:gap-2 xl:gap-2 lg:space-y-0">
          {/* <!-- Pricing Card 1--> */}
          <div class="flex flex-col max-w-[650px] max-h-full p-6 text-left text-gray-900 bg-white" data-aos="fade-right">
             <div className="lg:pt-8 sm:pt-8">
             <h2 className="mb-4 text-xl text-[#010440] dark:text-white font-lufgaBold tracking-wide" data-aos="fade-down">
             Four Bedroom House.
                        </h2>
            {/* <p class=" text-[#010440] sm:text-[16px] dark:text-gray-600 font-lufgaBold mr-8">
            Cost
            </p>
            <p class=" text-[#212121] sm:text-[13px] mb-4 dark:text-gray-600 font-lufga mr-8">
            A 4 Bedroom Maisonette will cost <b>KES 4.9M</b> monthly instalments of KES <b>55,747</b>
            </p> */}
            <p class=" text-[#010440] sm:text-[16px] mb-4 dark:text-gray-600 font-lufgaBold mr-8">
            Features
            </p>

            <p class=" text-[#212121] lg:text-[14px] sm:text-[14px] dark:text-gray-600 font-lufgaBold mr-8 tracking-tight">
            Ground Floor
            </p>
            <p class=" text-[#212121] lg:text-[13px] sm:text-[14px] mb-2 dark:text-gray-600 font-lufga mr-8 tracking-tight">
            Dining Area, Living Room, Pantry, One Bedroom ensuite, Open Plan Kitchen, Cloakroom, Foyer at the main entrance, 2 steel doors, 5000ltrs internal storage tank & 5000l reserve tank.

            </p>

            <p class=" text-[#212121] lg:text-[14px] sm:text-[14px] dark:text-gray-600 font-lufgaBold mr-8 tracking-tight">
            1st Floor
            </p>
            <p class=" text-[#212121] lg:text-[13px] sm:text-[14px] mb-4 dark:text-gray-600 font-lufga mr-8 tracking-tight">
            Master Ensuite Bedroom with walk-in closet and balcony, 2 bedrooms with built-in wardrobes and a shared washroom, One Extra balcony, Stairs, and tiled floors.
            </p>



             </div>

          </div>
            {/* <!-- Pricing Card 2--> */}
            <div class="flex flex-col max-w-[800px] max-h-full p-6 text-left text-gray-900 bg-white" data-aos="fade-left">
             <div className="lg:pt-8 sm:pt-8 -m-4">
                         <img
                alt="gallery"
                class="h-75 w-full rounded-[20px] object-cover object-center"
                src={house3} />

             </div>

          </div>

        </div>


        <div class="space-y-2 lg:grid lg:grid-cols-2 sm:gap-2 xl:gap-2 lg:space-y-0" data-aos="fade-up">
          {/* <!-- Pricing Card 1--> */}
          <div class="flex flex-col max-w-[650px] max-h-full p-6 text-left text-gray-900 bg-white ">
             <div className="lg:pt-2 sm:pt-2">

            <p class=" text-[#FF002B] sm:text-[18px] dark:text-gray-600 font-lufgaBold mr-8">
            **Note
            </p>
            <p class="font-bold text-[#212121] sm:text-[14px] mb-4 dark:text-[#212121] font-lufga mr-8">
            <i>Excavation shall not be beyond 2 feet of black cotton and the gradient is flat or slightly slanting. Anything beyond a standard design, a tailored BQ will be prepared.</i>
            </p>


             </div>

          </div>


        </div>



      </div>


    </section>
  );
};

export default HouseDesignFeatures;

import React, {useState} from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import image1 from "../assets/account/LOGIN_PAGE_IMAGE.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL} from '../config';



const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [notification, setNotification] = useState('');
    axios.defaults.withCredentials = true;
    let navigate = useNavigate();
    const requestPasswordReset = async (e) => {
        e.preventDefault();
        try {
                await axios.post(`${BASE_URL}/api/request-password-reset`, { email })
                .then(res => {
                    if(res.data.Status === "Success"){
                        console.log(res.data);
                        //navigate('/reset-password');
                        alert("Instructions to reset your password have been sent to your registered email");
                        //return;
                    }else{
                        alert(res.data.Message);
                        return;
                    }
                })
                .catch(err => console.log(err));
          } catch (error) {
            console.error('Login failed:', error);
          }
      };
  const sectionStyle = {
    backgroundImage: `url(${image1})`,
};
    return (

        <main>
            <Header />
            <section className="bg-white dark:bg-gray-900 lg:mx-14 sm:mx-8 my-28">
            <div className="bg-white dark:bg-gray-900">
    <div className="flex justify-center h-[500px] rounded-[20px] border shadow-lg shadow-[#efd27b] mx-8">


        <div className="flex items-center w-full max-w-md px-8 md:px-2 mx-auto lg:w-2/6">
            <div className="flex-1">

                <div className="mt-8 lg:-ml-16">

                    <form onSubmit={requestPasswordReset}>
                        <div>
                        <div><h3 for="email" class="block mb-2 text-2xl text-[#F2B807] font-lufgaBold dark:text-gray-200">Enter Your registered email</h3></div>
                            <label htmlFor="email" className="block mb-2 text-lg text-gray-500 font-lufgaBold dark:text-gray-200">Email</label>
                            <input type="email"
                             value={email}
                             onChange={(e) => setEmail(e.target.value)}
                           placeholder="email" className="block w-full px-4 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border text-sm border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" required/>
                        </div>


                        <div className="mt-8 md:flex md:items-center">
                        <button type="submit" className="flex items-center rounded-lg bg-[#F2B807] px-5 py-2.5 text-center text-[12px] font-lufgaBold text-black hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300">
                            Submit
                        </button>

                        <p className="text-sm inline-block mt-4 text-center  md:mt-0 md:mx-6 text-gray-400"><Link to="/"login className="text-[#F2B807] focus:outline-none font-lufgaBold focus:underline hover:underline">Back to Login</Link>.</p>
                    </div>

                    </form>


                </div>
            </div>
        </div>

        <div className="hidden bg-cover lg:block lg:w-1/2 rounded-[20px] rounded-bl-none rounded-tl-none border" style={sectionStyle}>
            <div className="flex items-center h-full px-20">
                <div>
                    {/* <h2 class="text-2xl font-bold text-white sm:text-3xl">Meraki UI</h2> */}
                     {/* <img src={image1} alt="" class="h-[400px]" /> */}
                </div>
            </div>
        </div>
    </div>
</div>

            </section>
            <Footer />
        </main>
    );
}

export default ForgotPassword;
import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminHeader from "./Components/AdminHeader";
import Aside from "./Components/Aside";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import jwtDecode from 'jwt-decode';
import { BASE_URL } from "../../config";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(7);
  const [totalData, setTotalData] = useState(0);

  //const token = Cookies.get('token');
  const navigation = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const tokenPayload = jwtDecode(token);
      if(tokenPayload.role === "basic"){
        alert("You are not authorised to view this page");
        navigation('/login');

      }else{
        const headers = {
          authorization: `Bearer ${token}`,
        };

        fetch(`${BASE_URL}/api/payments`)
          .then((response) => response.json())
          .then((data) => {
            if (data.Status === "Success") {
              console.log(data.Transactions);
              setTransactions(data.Transactions);
              setFilteredTransactions(data.Transactions);
              setTotalData(data.Transactions.length);
            } else {
              alert("Error");
            }
          })
          .catch((error) => console.log(error));
      }

    }
  }, []);

  const totalPages = Math.ceil(filteredTransactions.length / dataPerPage);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredTransactions.slice(indexOfFirstData, indexOfLastData);

  const filterTransactions = (event) => {
    const searchValue = event.target.value.toLowerCase();
    if (searchValue === "") {
      setFilteredTransactions(transactions);
    } else {
      const filtered = transactions.filter((transaction) => {
        return (
          transaction.MpesaReceiptNumber.toLowerCase().includes(searchValue) ||
          transaction.Id.toString().includes(searchValue) ||
          transaction.PhoneNumber.includes(searchValue) ||
          transaction.ClientID.includes(searchValue)
          // Add more conditions for other fields you want to search by
        );
      });
      setFilteredTransactions(filtered);
    }
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };


  return (
    <section>
      <div>
        <AdminHeader />
        <div className="flex overflow-hidden bg-white pt-16">
          <Aside />
          <div
            className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10"
            id="sidebarBackdrop"
          ></div>
          <div
            id="main-content"
            className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
          >
            <main>
              <div className="pt-6 px-4">
                <div className="flex flex-col mt-6">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="relative mb-10 w-full flex items-center justify-between rounded-md">
                        <input
                          onChange={filterTransactions}
                          type="text"
                          name="search"
                          className="h-12 w-full cursor-text rounded-md border border-gray-100 bg-gray-100 py-4 pr-40 pl-12 shadow-sm outline-none focus:border-[#F2B807] focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          placeholder="Search by Reference No., ID, Phone number, etc"
                        />
                      </div>

                      <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                          <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr>
                              <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">
                                ID
                              </th>
                              <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">
                                Reference No.
                              </th>
                              <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">
                                Amount
                              </th>
                              <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                Phone Number
                              </th>
                              <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                ID / Passport No.
                              </th>
                              <th className="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                Transaction Date
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                            {filteredTransactions.length === 0 ? (
                              <tr>
                                <td
                                  colSpan="6"
                                  className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                                >
                                  No data found
                                </td>
                              </tr>
                            ) : (
                              currentData.map((transaction, index) => (
                                <tr key={index}>
                                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                    {transaction.Id}
                                  </td>
                                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                    {transaction.MpesaReceiptNumber}
                                  </td>
                                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                    {transaction.Amount.toLocaleString()}
                                  </td>
                                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                    {transaction.PhoneNumber}
                                  </td>
                                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                    {transaction.ClientID}
                                  </td>
                                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                    {transaction.TransactionDate}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <button
        onClick={prevPage}
        disabled={currentPage === 1}
        className="bg-[#F2B807] text-black py-1.5 px-2 rounded disabled:opacity-50">
        <div class="flex items-center -mx-1">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-1 rtl:-scale-x-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>

            <span class="mx-1">
                previous
            </span>
        </div>
        </button>

        <div className="text-gray-600">
          Page <a href="#" class="cursor-not-allowed inline-flex items-center justify-center px-4 py-1 mx-2 text-gray-700 transition-colors duration-300 transform bg-[#F2B807] rounded-lg dark:text-white dark:bg-gray-700">
          {currentPage}
            </a>
        of <a href="#" class="cursor-not-allowed inline-flex items-center justify-center px-4 py-1 mx-2 text-gray-700 transition-colors duration-300 transform bg-gray-100 rounded-lg dark:text-white dark:bg-gray-700">
          {totalPages}
            </a>
        </div>

        <button
        onClick={nextPage}
        disabled={currentPage === totalPages}
         className="bg-[#F2B807] text-black py-1.5 px-2 rounded disabled:opacity-50">
        <div class="flex items-center -mx-1">
            <span class="mx-1">
                Next
            </span>

            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-1 rtl:-scale-x-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
        </div>
    </button>
      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <p className="text-center text-sm text-gray-500 my-10">
            &copy; 2023 <a href="https://promittoltd.com" className="hover:underline" target="_blank">Promitto</a>. All rights reserved.
         </p>
          </div>
        </div>
        <script async defer src="https://buttons.github.io/buttons.js"></script>
        <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
      </div>
    </section>
  );
};

export default Transactions;

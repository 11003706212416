import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import jwtDecode from 'jwt-decode';
import AdminHeader from "./Components/AdminHeader";
import Aside from "./Components/Aside";
import {BASE_URL} from '../../config';
import {AiOutlineEye} from 'react-icons/ai';
import {GrDocumentImage} from 'react-icons/gr';

const Clients = () => {
    const [justifyActive, setJustifyActive] = useState("tab1");
    const [users, setUsers] = useState([]);
    const {id} = useParams();
    const [userProfile, setUserProfile] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(7);
    const navigate = useNavigate();
    const [role, setRole] = useState('');

    //const token = Cookies.get('token');
    //axios.defaults.withCredentials = true;
    const GetProfileData = (userId) =>{
      fetch(`${BASE_URL}/api/getUserProfile/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          console.log(data.Profile[0]);
          setUserProfile(data.Profile[0]);
        } else {
          //alert("Error");
          console.log(data.Message);
        }
      })
      .catch((error) => console.log(error));
    }
    useEffect(() => {

    const token = localStorage.getItem('token');
      if (token) {
        try {
            const tokenPayload = jwtDecode(token);
            setRole(tokenPayload.role);
            const expirationTime = new Date(tokenPayload.exp * 1000);
            const currentTime = new Date();

            if (currentTime > expirationTime) {
                Cookies.remove('token');
                localStorage.clear();
                navigate('/login');
            }else{
              GetProfileData(tokenPayload.id);
                const headers = {
                  authorization: `Bearer ${token}`,
                };

                fetch(`${BASE_URL}/api/getUsers`, { headers })
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.Status === "Success") {
                      console.log(data.Users);

                      setUsers(data.Users);
                      setFilteredUsers(data.Users);
                    } else {
                      console.log(data.Error);
                    }
                  })
                  .catch((error) => console.log(error));

                  //profile

            }
        } catch (error) {
            // Error decoding token or token is invalid

        }
    } else {
        // No token found, user is not authenticated
        navigate('/login');
    }


    }, []);



  // Search handler function
  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    if (!searchTerm) {
      setFilteredUsers(users); // Reset to original users if search term is empty
    } else {
      const filtered = users.filter((user) =>
        user.FullName.toLowerCase().includes(searchTerm) ||
        user.DateOfBirth.toString().includes(searchTerm) ||
        user.NationalID.toString().includes(searchTerm) ||
        user.KRAPin.toLowerCase().includes(searchTerm) ||
        user.County.toLowerCase().toString().includes(searchTerm) ||
        user.PhoneNumber.includes(searchTerm)
      );
      setFilteredUsers(filtered);
    }
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredUsers.length / dataPerPage);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredUsers.slice(indexOfFirstData, indexOfLastData);

      const prevPage = () => {
        setCurrentPage(currentPage - 1);
      };

      const nextPage = () => {
        setCurrentPage(currentPage + 1);
      };


  return (
    <section>
<div>
  <AdminHeader />
   <div className="flex overflow-hidden bg-white pt-16">
      <Aside />
      <div className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
      <div id="main-content" className="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
         <main>
            <div className="pt-6 px-4">

            <div class="flex flex-col mt-6">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

            <div className="relative mb-10 w-full flex items-center justify-between rounded-md">
              <input
                onChange={handleSearch}
                type="text"
                name="search"
                className="h-12 w-full cursor-text rounded-md border border-gray-100 bg-gray-100 py-4 pr-40 pl-12 shadow-sm outline-none focus:border-[#F2B807] focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search by any field"
              />
            </div>
                <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead class="bg-gray-50 dark:bg-gray-800">
                            <tr>
                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">Full Names</th>


                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">DOB</th>

                            <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-left text-gray-500 dark:text-gray-400">ID or Passport No.</th>




                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">KRA PIN No.</th>

                                {/* <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">County</th> */}
                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Phone No</th>
                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Project Type</th>
                                <th scope="col" class="px-4 py-3.5 text-sm font-lufgaBold text-left rtl:text-right text-gray-500 dark:text-gray-400">Action</th>

                                {/* <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th> */}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {!filteredUsers.length ? (
                            <tr>
                              <td
                                colSpan="9"
                                className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 text-center"
                              >
                                No data found
                              </td>
                            </tr>
                          ) : (
                            currentData.map((user, index) => (
                              <tr key={index}>
                                <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.FullName.toUpperCase()}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.DateOfBirth}</td>

                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.NationalID}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.KRAPin}</td>
                                            {/* <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.County}</td> */}
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.PhoneNumber}</td>
                                            <td class="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">{user.projectType}</td>

                                            <td class="px-4 py-4 text-sm whitespace-nowrap">
                                                <div class="flex items-center gap-x-4">
                                                  {role === "super" && (
                                                     <>
                                                     <Link to={`/registered-documents/${user.ID}`}><GrDocumentImage className="h-6 w-6"/></Link>
                                                     <button class="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                      </button><Link to={`/client-profile/${user.ID}`} class="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                          </svg>
                                        </Link></>

                                                  )}



                                                    <Link to={`/client-project/${user.ID}`} class="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none">
                                                         <AiOutlineEye className="text-red h-6 w-6"/>
                                                    </Link>
                                                </div>
                                            </td>
                              </tr>
                            ))
                          )}
                        </tbody>


                    </table>
                </div>
                {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <button
        onClick={prevPage}
        disabled={currentPage === 1}
        className="bg-[#F2B807] text-black py-1.5 px-2 rounded disabled:opacity-50">
        <div class="flex items-center -mx-1">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-1 rtl:-scale-x-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>

            <span class="mx-1">
                previous
            </span>
        </div>
    </button>

        <div className="text-gray-600">
          Page <a href="#" class="cursor-not-allowed inline-flex items-center justify-center px-4 py-1 mx-2 text-gray-700 transition-colors duration-300 transform bg-[#F2B807] rounded-lg dark:text-white dark:bg-gray-700">
          {currentPage}
            </a>
        of <a href="#" class="cursor-not-allowed inline-flex items-center justify-center px-4 py-1 mx-2 text-gray-700 transition-colors duration-300 transform bg-gray-100 rounded-lg dark:text-white dark:bg-gray-700">
          {totalPages}
            </a>
        </div>

        <button
        onClick={nextPage}
        disabled={currentPage === totalPages}
         className="bg-[#F2B807] text-black py-1.5 px-2 rounded disabled:opacity-50">
        <div class="flex items-center -mx-1">
            <span class="mx-1">
                Next
            </span>

            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-1 rtl:-scale-x-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
        </div>
    </button>
      </div>
            </div>
        </div>
    </div>
            </div>
         </main>
         <p className="text-center text-sm text-gray-500 my-10">
            &copy; 2023 <a href="https://promittoltd.com" className="hover:underline" target="_blank">Promitto</a>. All rights reserved.
         </p>
      </div>
   </div>
   <script async defer src="https://buttons.github.io/buttons.js"></script>
   <script src="https://demo.themesberg.com/windster/app.bundle.js"></script>
</div>
</section>
  );
};

export default Clients;

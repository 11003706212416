import React, {useEffect} from 'react';
import logo from '../../assets/promitto_logo.jpg';
import AOS from 'aos';
import ScrollToTop from 'react-scroll-to-top';
import { FaTwitterSquare, FaPinterest, FaFacebook, FaInstagram, FaYoutube, FaEnvelope, FaTiktok } from 'react-icons/fa';
import {BsFillArrowUpSquareFill} from 'react-icons/bs';
const Footer = () => {
    useEffect(() => {
        AOS.init({ duration: 2000});
    },[]);

    return (
        <><ScrollToTop smooth component={<BsFillArrowUpSquareFill className='h-10 w-10 text-[#F2B807]' />}/>
        <section className='mx-4'>
            <footer class="bg-[#010440] dark:bg-gray-800 border rounded-lg border-gray-200 md:mx-2 mb-8">
                <div class="container p-6 mx-auto">
                    <div class="lg:flex my-8">
                        <div class="w-full sm:mx-4 lg:w-2/5">
                            <div class="px-2">
                                <div className="flex items-left justify-left mb-4" data-aos="fade-down">
                                    <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center">
                                        <img src={logo} alt="Profile Image" className="rounded-full object-cover w-3/4 h-3/4" />
                                    </div>
                                </div>
                                <h3 class="mb-6 text-lg font-semibold font-lufga text-[#F2B807] uppercase dark:text-white" data-aos="fade-down">PROMITTO LTD</h3>
                                <p class="max-w-sm mt-2 text-gray-200 font-lufga dark:text-gray-400" data-aos="fade-up">With Promitto Ltd you can expect Exceptional Homes and Unforgettable Memories. Enroll with us and Experience the Best of Kenya's <br />Real Estate offers.</p>


                            </div>
                        </div>

                        <div class="mt-6 ml-6 lg:ml-6 lg:mt-12 lg:flex-1">
                            <hr class="h-px my-6 mr-6 bg-[#F2B807] border-none dark:bg-gray-700" data-aos="fade-down" />

                            <div class="lg:ml-6 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                                <div data-aos="fade-right">
                                    <h3 class="mb-4 text-[#F2B807] font-lufga text-[18px] font-semibold">Help Desk</h3>
                                    <p class="block mt-2 text-[16px] font-lufga text-gray-200 text-bold font-semibold dark:text-gray-400">Email</p>
                                    <p class="block mt-2 text-sm font-lufga text-gray-200 dark:text-gray-400">info@promittoltd.com</p>
                                    <p class="block mt-2 text-[16px] font-lufga text-gray-200 text-bold font-semibold dark:text-gray-400">Telephone</p>
                                    <p class="block mt-2 text-sm font-lufga text-gray-200 dark:text-gray-400">(+254) 729 506 506</p>
                                </div>

                                <div data-aos="fade-up">
                                    <h3 class="mb-4 text-[#F2B807] text-[18px] font-lufga font-semibold">Location</h3>
                                    <p class="block mt-2 text-[16px] text-gray-200 text-bold font-lufga font-semibold dark:text-gray-400">Head Office</p>
                                    <p class="block mt-2 text-sm font-lufga text-gray-200 dark:text-gray-400">
                                        Pension Towers, Mezzanine Floor, Loita Street, Nairobi.</p>
                                    <p class="block mt-2 text-[16px] text-gray-200 text-bold font-lufga font-semibold dark:text-gray-400">Address</p>
                                    <p class="block mt-2 text-sm font-lufga text-gray-200 dark:text-gray-400">
                                        P. 0. Box 9388, 00100<br /> Nairobi, Kenya</p>
                                </div>

                                <div data-aos="fade-left">
                                    <h3 class="mb-4 text-[#F2B807] text-[18px] font-lufga font-semibold">Socials</h3>
                                    <p class="block mt-2 text-[16px] text-gray-200 font-lufga text-bold font-semibold dark:text-gray-400">Find us on.</p>
                                    <p className='mt-3 text-sm font-lufga text-gray-200'>Follow us, like and get updates on investment opportunities!</p>
                                    <div class="flex mt-6 -mx-2">
                                        <a href="https://web.facebook.com/PromittoSacco/?_rdc=1&_rdr"
                                            class="mx-2 text-[#F2B807] text-lg transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                            aria-label="Github">
                                            <FaFacebook />
                                        </a>
                                        <a href="https://www.instagram.com/promitto_ltd/"
                                            class="mx-2 text-[#F2B807] text-lg transition-colors duration-300 dark:text-gray-300 hover:text-orange-500 dark:hover:text-blue-400"
                                            aria-label="Github">
                                            <FaInstagram />
                                        </a>
                                        <a href="https://twitter.com/PromittoLtd"
                                            class="mx-2 text-[#F2B807] text-lg transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                            aria-label="Github">
                                            <FaTwitterSquare />
                                        </a>
                                        {/* <a href="#"
                                            class="mx-2 text-[#F2B807] text-lg transition-colors duration-300 dark:text-gray-300 hover:text-red-600 dark:hover:text-blue-400"
                                            aria-label="Github">
                                            <FaYoutube />
                                        </a> */}
                                        <a href="https://www.tiktok.com/@jimmykagoni7"
                                            class="mx-2 text-[#F2B807] text-lg transition-colors duration-300 dark:text-gray-300 hover:text-white dark:hover:text-blue-400"
                                            aria-label="Github">
                                            <FaTiktok />
                                        </a>
                                        {/* <a href="#"
                                            class="mx-2 text-[#F2B807] text-lg transition-colors duration-300 dark:text-gray-300 hover:text-red-700 dark:hover:text-blue-400"
                                            aria-label="Github">
                                            <FaEnvelope />
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </footer>

        </section></>
    );
};

export default Footer;
import React, { useEffect } from "react";
import { BsArrowRightCircle, BsDot } from "react-icons/bs";
import { Link } from "react-router-dom";
import image1 from "../../assets/about-us/image1.png";
import image2 from "../../assets/about-us/image2.png";
import AOS from "aos";
const InvestmentOpportunities = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <section class="bg-white dark:bg-gray-900">
      <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
        <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
          <h2
            className="mb-4 text-4xl font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide"
            data-aos="fade-down"
          >
            Investment Opportunities.
          </h2>
          <p
            className="mb-5 text-center text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga"
            data-aos="fade-up"
          >
            Promitto Ltd is your gateway to lucrative investment opportunities.
            With our extensive expertise in the financial sector and a deep
            understanding of market dynamics, we are dedicated to helping our
            clients maximize their investment potential.
          </p>
        </div>

        <div
          class="space-y-2 lg:grid lg:grid-cols-5 gap-4 sm:gap-6 xl:gap-2 lg:space-y-0"
          data-aos="zoom-in"
        >
          <div class="bg-white col-span-2 flex flex-col p-3 mx-auto border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
            {/* <!-- Pricing Card --> */}
            <div class="flex flex-col max-w-lg p-2 mx-auto lg:mx-8 text-left text-gray-900 bg-whitexl:p-2 dark:bg-gray-800 dark:text-white border border-x-0 border-b-[3px] border-t-0 border-[#F2B807]">
              <div class="flex items-left mt-6 mx-2">
                <h3 className="text-[#F2B807] font-lufgaBold text-4xl -mt-0.5 -ml-4">
                  01
                </h3>
                <div class="-mt-0.5 ml-4 -mr-5">
                  <h4 class="text-gray-800 dark:text-white font-lufgaBold text-xl">
                    Standard Finishes.
                  </h4>
                  <span className="text-[12px] text-black dark:text-gray-600 font-lufga leading-tight">
                    Basic or typical level of finishing provided in a
                    construction project without any additional upgrades or
                    enhancements. These finishes are often chosen to meet the
                    minimum requirements and budget constraints of a project.
                  </span>
                </div>
              </div>
            </div>
            {/* <!-- Pricing Card --> */}
            <div class="flex flex-col max-w-lg p-2 mx-auto lg:mx-8 text-left text-gray-900 bg-whitexl:p-2 dark:bg-gray-800 dark:text-white border border-x-0 border-b-[3px] border-t-0 border-[#F2B807]">
              <div class="flex items-left mt-6 mx-2">
                <h3 className="text-[#F2B807] font-lufgaBold text-4xl -mt-0.5 -ml-4">
                  02
                </h3>
                <div class="-mt-0.5 ml-4">
                  <h4 class="text-gray-800 dark:text-white font-lufgaBold text-xl">
                    Hybrid Finishes.
                  </h4>
                  <span class="text-[12px] text-black dark:text-gray-600 font-lufga leading-tight tracking-tight">
                    Also known as semi-custom finishes, refer to a combination
                    of standard finishes and upgraded or customized elements in
                    a construction project. These finishes offer a middle ground
                    between standard finishes and fully custom or high-end
                    finishes.
                  </span>
                </div>
              </div>
            </div>
            {/* <!-- Pricing Card --> */}
            <div class="flex flex-col max-w-lg p-2 mx-auto lg:mx-8 text-left text-gray-900 bg-whitexl:p-2 dark:bg-gray-800 dark:text-white border border-x-0 border-b-[3px] border-t-0 border-[#F2B807]">
              <div class="flex items-left mt-6 mx-2">
                <h3 className="text-[#F2B807] font-lufgaBold text-4xl -mt-0.5 -ml-4">
                  03
                </h3>
                <div class="-mt-0.5 ml-4">
                  <h4 class="text-gray-800 dark:text-white font-lufgaBold text-xl">
                    High-end Finishes.
                  </h4>
                  <span class="text-[12px] text-black dark:text-gray-600 font-lufga leading-tight tracking-tight">
                    premium or luxury-level finishings that are characterized by
                    top-quality materials, exquisite craftsmanship, and a high
                    level of customization. These finishes are often associated
                    with upscale or luxury construction projects where attention
                    to detail and exceptional aesthetics are paramount.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-[#F2B807] col-span-3 flex flex-col p-3 mx-auto border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
            <div className="sm:mx-8 mt-10">
              <h1 class="flex mb-4 mt-16 text-5xl font-lufgaBold tracking-wide">
                Become a home owner today by Enrolling as a member.
              </h1>
              <p class="flex text-[#010440] lg:text-lg sm:text-[12px] dark:text-gray-400 font-lufga">
                Here at Promitto Ltd we to turn your dreams into reality, as we
                pride ourselves on delivering extraordinary service, impeccable
                quality, and unforgettable living experiences.
              </p>

              <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4 pt-8">
                <Link
                to='/register'
                  class="inline-flex items-center font-lufga bg-[#F2B807] justify-center w-full px-5 py-3 text-sm font-bold text-center text-black border border-black rounded-lg sm:w-auto  focus:ring-4 focus:ring-gray-100 dark:text-white"
                  data-aos="fade-up"
                >
                  Enroll Now{" "}
                  <BsArrowRightCircle className="text-black font-bold ml-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="max-w-screen-xl -px-16 mx-auto lg:mb-20 lg:-px-16 -mt-8">
        <div class="space-y-2 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          {/* <!-- Pricing Card --> */}
          <div>
          <div class="flex relative overflow-hidden rounded-[20px]">
            <img src={image1} alt="image" class="w-full" />

          </div>

          </div>
          {/* <!-- Pricing Card --> */}
          <div>
          <div class="flex relative overflow-hidden rounded-[20px] bg-white border shadow">
          <article
          class="h-90 mx-6 my-14 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden pb-2 transition-transform duration-200 hover:translate-y-2"
          data-aos="fade-left"
        >
          <h3 class="mb-4 text-2xl text-[#F2B807] font-semibold font-lufgaBold">
          Requirements for  Registration
          </h3>
          <ol class="space-y-2 text-gray-600 list-outside dark:text-gray-400">
            <li>
              <ul class="pl-5 mt-2 space-y-1 list-disc list-outside">
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                  <li className="text-[20px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                  Copy of KRA Certificate

                  </li>
                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                  <li className="text-[20px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                  Copy of Title Deed
                  </li>
                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                  <li className="text-[20px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                  PIN of site location
                  </li>
                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                  <li className="text-[20px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                  House Design
                  </li>
                </div>
                <div className="border border-[#F2B807] border-x-0 border-t-0 border-b-[3px]">
                  <li className="text-[20px] mb-2 font-lufgaBold text-gray-700 font-semibold">
                  A deposit of 30K for registration and project assessment
                  </li>
                </div>
              </ul>
            </li>
          </ol>
        </article>
          </div>
          </div>
          {/* <!-- Pricing Card --> */}
          <div>
          <div class="flex relative overflow-hidden rounded-[20px] -mt-2">
            <img src={image2} alt="image" class="w-80" />

          </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default InvestmentOpportunities;

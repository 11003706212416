import React, { useEffect} from "react";
import "./Stats.css";
import AOS from "aos";

const Statistics = () => {
  useEffect(() => {
    AOS.init({ duration: 2000});
},[]);
  return (
    <section class="sm:mx-4 bg-white dark:bg-gray-900">
      <div class="max-w-screen-xl px-10 py-8 mx-auto lg:py-24">
        <div class="space-y-2 grid sm:grid-cols-3 sm:gap-2 xl:gap-2 lg:space-y-0">
          {/* <!-- Pricing Card --> */}

          <div class="flex flex-col mx-auto text-left text-gray-900 bg-white xl:p-2 dark:bg-gray-800 dark:text-white" data-aos="fade-right">
            <div class="flex flex-col items-center">
              <p class="text-black mb-1 my-12 px-2 text-4xl md:text-5xl font-extrabold tracking-wide font-lufga">
              520+
              </p>
              <p class="text-gray-500 text-[15px] font-semibold font-lufga mb-6">
              On-going Housing Projects
              </p>
            </div>
          </div>
          {/* <!-- Pricing Card --> */}
          <div class="flex flex-col mx-auto text-left text-gray-900 bg-white xl:p-2 dark:bg-gray-800 dark:text-white" data-aos="zoom-out">
            <div class="flex flex-col items-center border border-r-2 border-l-2 border-y-0 border-gray-300">
              <p class="text-black mb-1 m-12 px-10 text-4xl md:text-5xl font-extrabold tracking-wide font-lufga">
                253+
              </p>
              <p class="text-gray-500 text-[15px] font-lufga mb-6 font-semibold">
                Satisfied Clients
              </p>
            </div>
          </div>

          {/* <!-- Pricing Card --> */}
          <div class="flex flex-col mx-auto text-left text-gray-900 bg-white xl:p-2 dark:bg-gray-800 dark:text-white" data-aos="fade-left">
            <div class="flex flex-col items-center">
              <p class="text-black mb-1 m-12 text-4xl md:text-5xl font-extrabold tracking-wide font-lufga">
                25+
              </p>
              <p class="text-gray-500 text-[15px] font-lufga mb-6 font-semibold">
                New Projects Every Month
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics;

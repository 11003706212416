import React, {useEffect} from 'react';
import AOS from 'aos';
import { FaUserTie } from 'react-icons/fa';
import { BiTrendingUp } from 'react-icons/bi';
import { FaHandHoldingHeart } from 'react-icons/fa';

import icon1 from '../../assets/how-to-own/icons8-voice-96.png';
import icon2 from '../../assets/how-to-own/icons8-finger-print-64.png'
import icon3 from '../../assets/how-to-own/icons8-assignment-96.png'
import icon4 from '../../assets/how-to-own/icons8-checked-96.png'
import icon5 from '../../assets/how-to-own/icons8-handshake-medium-skin-tone-96.png'
import icon6 from '../../assets/how-to-own/icons8-construction-100.png'


const ConstructionProcess = () => {
    useEffect(() => {
        AOS.init({ duration: 2000});
    },[]);
    return (
        <section class="bg-white dark:bg-gray-900 -mt-8">
        <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
            <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                    <h2 className="mb-4 text-4xl font-bold tracking-wide text-[#F2B807] dark:text-white font-lufga" data-aos="fade-down">Construction Process</h2>

            </div>
            <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white xl:p-8 dark:bg-gray-800 dark:text-white" data-aos="fade-right">

                    <div className="flex items-left justify-left mb-2">
                        <img className='h-[70px]' src={icon1} alt="" />
                    </div>
                    <h3 class="mb-2 text-xl font-lufgaBold">Expression of Interest</h3>
                    <p class="text-gray-600 sm:text-[13px] tracking-tight dark:text-gray-400 font-lufga">
                    Client expresses interest to construct and commits with a deposit of KES 30,000. Promitto kicks off by performing due diligence including a site visit, title search and KYC verification.
                  </p>

                   </div>
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white xl:p-8 dark:bg-gray-800 dark:text-white" data-aos="fade-right">

                    <div className="flex items-left justify-left mb-2">
                        <img className='h-[70px]' src={icon2} alt="" />
                    </div>
                    <h3 class="mb-2 text-xl font-lufgaBold">Client Authorization stage</h3>
                    <p class="text-gray-600 sm:text-[13px] tracking-tight dark:text-gray-400 font-lufga">
                    Promitto shares project details with contractor for preparation of a Bill of Quantities.
                     </p>

                   </div>
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white xl:p-8 dark:bg-gray-800 dark:text-white" data-aos="fade-right">

                    <div className="flex items-left justify-left mb-2">
                        <img className='h-[70px]' src={icon3} alt="" />
                    </div>
                    <h3 class="mb-2 text-xl font-lufgaBold">Project Assignment</h3>
                    <p class="text-gray-600 sm:text-[13px] tracking-tight dark:text-gray-400 font-lufga">
                    Promitto provides Bill of Quantities within a maximum of 14 working days.
                    </p>

                   </div>
            </div>

            <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 -mt-2">
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white xl:p-8 dark:bg-gray-800 dark:text-white" data-aos="fade-right">

                    <div className="flex items-left justify-left mb-2">
                        <img className='h-[70px]' src={icon4} alt="" />
                    </div>
                    <h3 class="mb-2 text-xl font-lufgaBold">Clearance stage</h3>
                    <p class="text-gray-600 sm:text-[13px] tracking-tight dark:text-gray-400 font-lufga">
                    Promitto shares the Bill of Quantities with the Client. Client makes deposit of 30% to Promitto.
                    </p>

                   </div>
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white xl:p-8 dark:bg-gray-800 dark:text-white" data-aos="fade-right">

                    <div className="flex items-left justify-left mb-2">
                        <img className='h-[70px]' src={icon5} alt="" />
                    </div>
                    <h3 class="mb-2 text-xl font-lufgaBold">Confirmation stage</h3>
                    <p class="text-gray-600 sm:text-[13px] tracking-tight dark:text-gray-400 font-lufga">
                    Promitto shares the Bill of Quantities with the Client. Client makes deposit of 30% to Promitto.
                    </p>

                   </div>
                {/* <!-- Pricing Card --> */}
                <div class="flex flex-col max-w-lg p-6 mx-auto text-left text-gray-900 bg-white xl:p-8 dark:bg-gray-800 dark:text-white" data-aos="fade-right">

                    <div className="flex items-left justify-left mb-2">
                        <img className='h-[70px]' src={icon6} alt="" />
                    </div>
                    <h3 class="mb-2 text-xl font-lufgaBold">Implementation, monitoring and evaluation stage</h3>
                    <p class="text-gray-600 sm:text-[13px] tracking-tight dark:text-gray-400 font-lufga">
                    Promitto flags off construction and oversees management of project. Construction ends in 6 - 12 months as client continues to make monthly repayments. Client starts making monthly repayments a month after construction begins.
                    </p>

                   </div>
            </div>
        </div>
      </section>
    );
};

export default ConstructionProcess;
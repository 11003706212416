import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import OwnHome from "../components/HowToOwn/OwnHome";
import TermsConditions from "../components/HowToOwn/TermsConditions";
import HouseDesignFeatures from "../components/HowToOwn/HouseDesignFeatures";
import ConstructionProcess from "../components/HowToOwn/ConstructionProcess";
import Savings from "../components/HowToOwn/Savings";

const HowToOwn = () => {
  return (
    <main>
      <Header />
      <OwnHome />
      <TermsConditions />
      <div class="max-w-screen-md mx-auto mb-8 lg:mt-12 sm:mt-8 text-center lg:mb-12">
        <h2
          className="my-4 lg:text-4xl sm:text-3xl font-bold text-[#F2B807] dark:text-white font-lufga tracking-wide"
          data-aos="fade-down"
        >
          House Designs & Features
        </h2>
      </div>
      <HouseDesignFeatures />
      <ConstructionProcess />
      <Savings />
      <Footer />
    </main>
  );
};

export default HowToOwn;
